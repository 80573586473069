import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserModel } from '../_models/user.model';

interface ContactMail {
  name: string;
  phone: string;
  message: string;
  email: string;
}

interface RegisterMail {
  email: string;
}

interface ValidateUserMail {
  email: string;
}

interface ResponseCode {
  code: string;
}

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  constructor(private http: HttpClient) {}

  async contactMail(input: any): Promise<Observable<any>> {
    const path = environment.apiBasePath + 'email/contactMail';
    return this.http.post<any>(path, input);
  }

  async registerMail(input: any): Promise<Observable<any>> {
    const path = environment.apiBasePath + 'email/registerMail';
    return this.http.post<any>(path, input);
  }

  async validateUserMail(input: any): Promise<Observable<any>> {
    const headers: Record<string,string> = {
      'Authorization': sessionStorage.getItem('token')!
    }
    const path = environment.apiBasePath + 'email/validateUserMail';
    return this.http.post<any>(path, input, {headers});
  }

  async lostPasswordMail(input: any): Promise<Observable<any>> {
    const path = environment.apiBasePath + 'email/contactMail';
    return this.http.post<any>(path, input);
  }
}
