<div *ngIf="isLoaded">
  <div *ngIf="admin.role === 1" class="dashboard" fxLayout="row wrap">
    <div fxFlex="15%" fxFlex.md="100%" fxFlex.xs="100%" fxFlex.sm="100%">
      <div>
        <hr class="hr" />
        <br />
        <div>
          <button (click)="goTo('addNews')" class="menuItem" mat-button>
            <mat-icon class="iconMenu">local_offer</mat-icon>
            <a class="textMenu">Ofertas</a>
          </button>
          <button (click)="goTo('addComunicate')" class="menuItem" mat-button>
            <mat-icon class="iconMenu">announcement</mat-icon>
            <a class="textMenu">Comunicados</a>
          </button>
          <button (click)="goTo('votations')" class="menuItem" mat-button>
            <mat-icon class="iconMenu">how_to_vote</mat-icon>
            <a class="textMenu">Votaciones</a>
          </button>
          <button (click)="goTo('updateAgreement')" class="menuItem" mat-button>
            <mat-icon class="iconMenu">donut_small</mat-icon>
            <a class="textMenu">Convenio</a>
          </button>
          <button (click)="goTo('users')" class="menuItem" mat-button>
            <mat-icon class="iconMenu">supervised_user_circle</mat-icon>
            <a class="textMenu">Usuarios</a>
          </button>
          <button (click)="goTo('updateLinks')" class="menuItem" mat-button>
            <mat-icon class="iconMenu">dashboard</mat-icon>
            <a class="textMenu">Gestión</a>
          </button>
        </div>
      </div>
    </div>
    <div fxFlex="85%" fxFlex.md="100%" fxFlex.xs="100%" fxFlex.sm="100%">
      <hr />
      <br />
      <app-add *ngIf="isAdd"></app-add>
      <app-add-com *ngIf="isAddComunicate"></app-add-com>
      <app-update-agreement *ngIf="isUpdateAgr"></app-update-agreement>
      <app-update-links *ngIf="isUpdateLinks"></app-update-links>
      <app-votations *ngIf="isVotation"></app-votations>
      <app-users *ngIf="isUser"></app-users>
    </div>
  </div>

  <div *ngIf="admin.role === 2" class="dashboard" fxLayout="row wrap">
    <div fxFlex="15%" fxFlex.md="100%" fxFlex.xs="100%" fxFlex.sm="100%">
      <div>
        <hr class="hr" />
        <br />
        <div>
          <button (click)="goTo('addNews')" class="menuItem" mat-button>
            <mat-icon class="iconMenu">local_offer</mat-icon>
            <a class="textMenu">Ofertas</a>
          </button>
          <button (click)="goTo('addComunicate')" class="menuItem" mat-button>
            <mat-icon class="iconMenu">announcement</mat-icon>
            <a class="textMenu">Comunicados</a>
          </button>
          <button (click)="goTo('votations')" class="menuItem" mat-button>
            <mat-icon class="iconMenu">how_to_vote</mat-icon>
            <a class="textMenu">Votaciones</a>
          </button>
          <button (click)="goTo('updateAgreement')" class="menuItem" mat-button>
            <mat-icon class="iconMenu">donut_small</mat-icon>
            <a class="textMenu">Convenio</a>
          </button>
          <button (click)="goTo('updateLinks')" class="menuItem" mat-button>
            <mat-icon class="iconMenu">dashboard</mat-icon>
            <a class="textMenu">Gestión</a>
          </button>
        </div>
      </div>
    </div>
    <div fxFlex="85%" fxFlex.md="100%" fxFlex.xs="100%" fxFlex.sm="100%">
      <hr />
      <br />
      <app-add *ngIf="isAdd"></app-add>
      <app-add-com *ngIf="isAddComunicate"></app-add-com>
      <app-update-agreement *ngIf="isUpdateAgr"></app-update-agreement>
      <app-update-links *ngIf="isUpdateLinks"></app-update-links>
      <app-votations *ngIf="isVotation"></app-votations>
    </div>
  </div>

  <div *ngIf="admin.role === 3" class="dashboard" fxLayout="row wrap">
    <div fxFlex="15%" fxFlex.md="100%" fxFlex.xs="100%" fxFlex.sm="100%">
      <div>
        <hr class="hr" />
        <br />
        <div>
          <button (click)="goTo('addNews')" class="menuItem" mat-button>
            <mat-icon class="iconMenu">local_offer</mat-icon>
            <a class="textMenu">Ofertas</a>
          </button>
          <button (click)="goTo('addComunicate')" class="menuItem" mat-button>
            <mat-icon class="iconMenu">announcement</mat-icon>
            <a class="textMenu">Comunicados</a>
          </button>
          <button (click)="goTo('votations')" class="menuItem" mat-button>
            <mat-icon class="iconMenu">how_to_vote</mat-icon>
            <a class="textMenu">Votaciones</a>
          </button>
          <button (click)="goTo('updateLinks')" class="menuItem" mat-button>
            <mat-icon class="iconMenu">dashboard</mat-icon>
            <a class="textMenu">Gestión</a>
          </button>
        </div>
      </div>
    </div>
    <div fxFlex="85%" fxFlex.md="100%" fxFlex.xs="100%" fxFlex.sm="100%">
      <hr />
      <br />
      <app-add *ngIf="isAdd"></app-add>
      <app-add-com *ngIf="isAddComunicate"></app-add-com>
      <app-update-links *ngIf="isUpdateLinks"></app-update-links>
      <app-votations *ngIf="isVotation"></app-votations>
    </div>
  </div>
</div>