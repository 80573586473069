import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Contact, GestionService } from 'src/app/_services/gestion.service';

interface FormInput {
  id: number;
  phone: number;
  email: string;
}

@Component({
  selector: 'app-form-contact',
  templateUrl: './form-contact.component.html',
  styleUrls: ['./form-contact.component.css'],
})
export class FormContactComponent implements OnInit {
  isSpinner: boolean = false;
  formGroup!: UntypedFormGroup;
  addOnBlur = true;
  isLoaded: boolean = false;
  formValue: Contact[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private gestionService: GestionService
  ) {}

  async ngOnInit(): Promise<void> {
    (await this.gestionService.getContact()).subscribe((data) => {
      this.formValue = data;
      this.formGroup = this.formBuilder.group({
        phone: [this.formValue[0].phone, Validators.required],
        email: [
          this.formValue[0].email,
          [Validators.required, Validators.email],
        ],
      });
      this.isLoaded = true;
    });
  }

  async onSubmit(input: FormInput): Promise<void> {
    if (this.formGroup.valid) {
      this.isSpinner = true;
      input.id = this.formValue[0].id;
      (await this.gestionService.setContact(input)).subscribe((data) => {
        this.formGroup = this.formBuilder.group({
          phone: [data.phone, Validators.required],
          email: [data.email, [Validators.required, Validators.email]],
        });
        this.isSpinner = false;
      });
    }
  }
}
