<div class="add-form" *ngIf="isLoaded">
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)" >
        <div class="form-input">
            <p>
                <mat-form-field appearance="outline">
                <mat-label>Reglamento</mat-label>

                    <input type="text" matInput formControlName="link1" required>
                    <!-- <mat-icon matSuffix>link1</mat-icon> -->
                    <mat-error *ngIf="formGroup.get('link1')?.errors && formGroup.get('link1')?.errors?.['required']">Reglamento is required.</mat-error>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="outline">
                <mat-label>Leyes Españolas</mat-label>

                    <input type="email" matInput formControlName="link2" required>
                    <!-- <mat-icon matSuffix>link2</mat-icon> -->
                    <mat-error *ngIf="formGroup.get('link2')?.errors && formGroup.get('link2')?.errors?.['required']">Opcion 1 obligatoria.</mat-error>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="outline">
                <mat-label>Autoridad Española</mat-label>

                    <input type="email" matInput formControlName="link3" required>
                    <!-- <mat-icon matSuffix>link3</mat-icon> -->
                    <mat-error *ngIf="formGroup.get('link3')?.errors && formGroup.get('link3')?.errors?.['required']">Opcion 1 obligatoria.</mat-error>
                </mat-form-field>
            </p>
        </div>
        <div class="action">
            <div class="button">
                <button class="send" type="submit" mat-button color="success">
                    Actualizar
                </button>
            </div>
            <div class="spinner">
                <mat-spinner *ngIf="isSpinner" [diameter]="30"></mat-spinner>
            </div>
        </div>
        
    </form>
</div>