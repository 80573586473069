import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Offer, OffersService } from 'src/app/_services/offers.service';

interface OfferType {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css'],
})
export class AddComponent {
  isSubmited: boolean = false;
  isLoaded: boolean = false;
  isAdd: boolean = false;
  isEdit: boolean = false;
  isSpinner: boolean = false;
  selectedValue: string = '';

  formGroup!: UntypedFormGroup;
  displayedColumns = ['title', 'description', 'link', 'type', 'action'];
  dataSource!: MatTableDataSource<Offer>;
  offerType: OfferType[] = [
    { value: 'Curso', viewValue: 'Curso' },
    { value: 'Oferta de empleo', viewValue: 'Oferta de empleo' },
  ];
  offers: Offer[] = [];
  offerToEdit!: Offer;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private offersService: OffersService
  ) {}

  async ngOnInit(): Promise<void> {
    this.isSubmited = false;

    (await this.offersService.getOffers()).subscribe((data) => {
      this.offers = data;
      this.dataSource = new MatTableDataSource(this.offers);

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });

    this.formGroup = this.formBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      link: [''],
      type: ['', Validators.required],
    });
    this.isLoaded = true;
  }

  ngAfterViewInit() {}

  openAdd(): void {
    document.getElementById('paginator')!.style.display = 'none';
    this.isSubmited = false;
    this.isAdd = true;
  }
  openEdit(element: any): void {
    document.getElementById('paginator')!.style.display = 'none';
    this.offerToEdit = element;
    this.formGroup = this.formBuilder.group({
      title: [element.title, Validators.required],
      description: [element.description, Validators.required],
      link: [element.link],
      type: [element.type, Validators.required],
    });
    this.isEdit = true;
  }

  async remove(element: any): Promise<void> {
    await this.offersService.deleteOffer(element.id);
    this.offers = this.offers.filter(({ id }) => id != element.id);
    this.dataSource = new MatTableDataSource(this.offers);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  goBack(): void {
    document.getElementById('paginator')!.style.display = 'block';
    this.formGroup = this.formBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      link: [''],
      type: ['', Validators.required],
    });
    this.isAdd = false;
    this.isEdit = false;
    this.isSubmited = false;
  }

  addOffer(input: Offer): void {
    this.offers.push(input);
    this.dataSource = new MatTableDataSource(this.offers);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.goBack();
  }

  editOffer(input: Offer): void {
    this.offers = this.offers.map((data) => {
      return data.id === this.offerToEdit.id
        ? {
            id: this.offerToEdit.id,
            title: input.title,
            description: input.description,
            link: input.link,
            type: input.type,
          }
        : data;
    });
    this.dataSource = new MatTableDataSource(this.offers);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.goBack();
  }

  async onSubmit(input: any): Promise<void> {
    if (this.formGroup.valid) {
      this.isSpinner = true;

      if (this.isAdd) {
        (await this.offersService.setOffers(input)).subscribe((data) => {
          this.isSpinner = false;
          this.isSubmited = true;
          setTimeout(() => {
            this.isSubmited = false;
            this.addOffer(data);
          }, 2000);
        });
      }
      if (this.isEdit) {
        (
          await this.offersService.editOffer(input, this.offerToEdit.id)
        ).subscribe((data) => {
          this.isSpinner = false;
          this.isSubmited = true;
          setTimeout(() => {
            this.isSubmited = false;
            this.editOffer(data);
          }, 2000);
        });
      }
    }
  }
}
