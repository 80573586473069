import { Component, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { Offer, OffersService } from 'src/app/_services/offers.service';

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.css'],
})
export class OffersComponent implements OnInit {
  courseClass: string = 'active';
  jobClass: string = 'no-active';
  isLoaded: boolean = false;
  offers: Offer[] = [];
  offersFiltered: Offer[] = [];

  constructor(private offersService: OffersService) {}

  async ngOnInit(): Promise<void> {
    (await this.offersService.getOffers()).subscribe((data) => {
      this.offers = data;
      this.offersFiltered = data;
      this.filterCursos();

      this.isLoaded = true;
    });
  }

  styleOptions(type: string): Object {
    let style: Object;
    type === 'Curso'
      ? (style = { color: '#011d35', border: '1px solid #011d35;' })
      : (style = {
          color: '#80a2d0',
          border: '1px solid #80a2d0;',
        });
    return style;
  }

  filterCursos(): void {
    this.setCourseStyle();
    this.offersFiltered = this.offers.filter((value) => value.type === 'Curso');
  }

  filterOffers(): void {
    this.setJobStyle();
    this.offersFiltered = this.offers.filter(
      (value) => value.type === 'Oferta de empleo'
    );
  }

  private setCourseStyle(): void {
    this.courseClass = 'active';
    this.jobClass = 'no-active';
  }

  private setJobStyle(): void {
    this.courseClass = 'no-active';
    this.jobClass = 'active';
  }
}
