<div class="first" *ngIf="isLoaded">
  <div>
    <h1>Ofertas</h1>

    <div class="tab" fxLayout="row wrap">
      <div [ngClass]="courseClass" fxFlex="50%" fxFlex.xs="50%" fxFlex.sm="50%" fxFlex.md="50%">
        <button class="tab-button" mat-button (click)="filterCursos()">Cursos</button>
      </div>
      <div [ngClass]="jobClass" fxFlex="50%" fxFlex.xs="50%" fxFlex.sm="50%" fxFlex.md="50%">
        <button class="tab-button" mat-button (click)="filterOffers()">Empleos</button>
      </div>
    </div>

    <div class="dad" fxLayout="row wrap">
      <div class="offers" fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="100%">
        <div fxLayout="row wrap">
          <div fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="50%" *ngFor="let offer of offersFiltered">
            <mat-card class="example-card mat-elevation-z0">
              <mat-card-title-group>
                <mat-card-title>{{ offer.title }}</mat-card-title>
                <mat-card-subtitle>
                  <mat-chip-list>
                    <mat-chip [ngStyle]="styleOptions(offer.type)">
                      {{ offer.type === 'Curso' ? 'Curso' : 'Oferta de empleo' }}
                    </mat-chip>
                  </mat-chip-list>
                </mat-card-subtitle>
              </mat-card-title-group>
              <mat-card-content>
                {{ offer.description }}
              </mat-card-content>
              <a class="link" target="_blank" [href]="offer.link">Más información aquí</a>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
  </div>