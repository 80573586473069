import { Component, OnInit } from '@angular/core';
import { HostListener } from '@angular/core';
import { AdminData, AdminService } from 'src/app/_services/admin.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  screenWidth: number = 0;
  menu: boolean = false;
  isVisible: boolean = false;
  isEdit: boolean = false;
  isAdd: boolean = false;
  isAddComunicate: boolean = false;
  isEditComunicate: boolean = false;
  isUpdateAgr: boolean = false;
  isUpdateLinks: boolean = true;
  isVotation: boolean = false;
  isUser: boolean = false;
  isLoaded: boolean = false;
  admin!: AdminData;
  constructor(private adminService: AdminService) {
    const adminSession = sessionStorage.getItem('adminSession');
    if (adminSession === null || adminSession === '') {
      window.location.href = environment.basePath;
    }
    this.getScreenSize();
  }

  async ngOnInit(): Promise<void> {
    const adminSession = sessionStorage.getItem('adminSession');
    if (adminSession)
      (await this.adminService.getAdminById(+adminSession)).subscribe(
        async (data) => {
          this.admin = data;
          this.isLoaded = true;
        }
      );
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(): void {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 1250) {
      this.isVisible = true;
    } else {
      this.isVisible = false;
    }
  }

  goTo(to: string): void {
    this.isAdd = false;
    this.isEdit = false;
    this.isAddComunicate = false;
    this.isEditComunicate = false;
    this.isUpdateAgr = false;
    this.isUpdateLinks = false;
    this.isVotation = false;
    this.isUser = false;
    switch (to) {
      case 'addNews':
        this.isAdd = true;
        break;
      case 'editNews':
        this.isEdit = true;
        break;
      case 'addComunicate':
        this.isAddComunicate = true;
        break;
      case 'editComunicate':
        this.isEditComunicate = true;
        break;
      case 'updateAgreement':
        this.isUpdateAgr = true;
        break;
      case 'updateLinks':
        this.isUpdateLinks = true;
        break;
      case 'votations':
        this.isVotation = true;
        break;
      case 'users':
        this.isUser = true;
        break;
    }
  }
}
