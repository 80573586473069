import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TopBarComponent } from './_components/top-bar/top-bar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatMenuModule } from '@angular/material/menu';
import { ContactComponent } from './_components/contact/contact.component';
import { FooterComponent } from './_components/footer/footer.component';
import { AboutUsComponent } from './_components/about-us/about-us.component';
import { ServicesComponent } from './_components/services/services.component';
import { FaqsComponent } from './_components/faqs/faqs.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { StudentRegistrationComponent } from './pages/student-registration/student-registration.component';
import { PrivacyStatementComponent } from './pages/privacy-statement/privacy-statement.component';
import { MembershipRegistrationComponent } from './pages/membership-registration/membership-registration.component';
import { MemberConsentComponent } from './pages/member-consent/member-consent.component';
import { LawsAgreementsComponent } from './pages/laws-agreements/laws-agreements.component';
import { HomeComponent } from './pages/home/home.component';
import { MatCardModule } from '@angular/material/card';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AddComponent } from './_components/add/add.component';
import { EditDeleteComponent } from './_components/edit-delete/edit-delete.component';
import { LoginComponent } from './pages/login/login.component';
import { MemberBenefitsComponent } from './pages/member-benefits/member-benefits.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { VotesComponent } from './pages/votes/votes.component';
import { SectorAgreementComponent } from './pages/sector-agreement/sector-agreement.component';
import { NewsComponent } from './pages/news/news.component';
import { AddComComponent } from './_components/add-com/add-com.component';
import { EditComComponent } from './_components/edit-com/edit-com.component';
import { UpdateAgreementComponent } from './_components/update-agreement/update-agreement.component';
import { UpdateLinksComponent } from './_components/update-links/update-links.component';
import { ModalComponent } from './_components/modal/modal.component';
import { VotationsComponent } from './_components/votations/votations.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { UsersComponent } from './_components/users/users.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TopBarDashboardComponent } from './_components/top-bar-dashboard/top-bar-dashboard.component';
import { ComunicateFormComponent } from './_components/comunicate-form/comunicate-form.component';
import { HttpClientModule } from '@angular/common/http';
import { FormNavComponent } from './_components/form-nav/form-nav.component';
import { FormAboutComponent } from './_components/form-about/form-about.component';
import { FormFaqsComponent } from './_components/form-faqs/form-faqs.component';
import { FormContactComponent } from './_components/form-contact/form-contact.component';
import { FormExternalLinksComponent } from './_components/form-external-links/form-external-links.component';
import { OffersComponent } from './pages/offers/offers.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { MatDialogModule } from '@angular/material/dialog';
import { FormBenefitsComponent } from './_components/form-benefits/form-benefits.component';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { FormPrivacyComponent } from './_components/form-privacy/form-privacy.component';
import { FormConsentComponent } from './_components/form-consent/form-consent.component';
import { FormAgreement1Component } from './_components/form-agreement1/form-agreement1.component';
import { FormAgreement2Component } from './_components/form-agreement2/form-agreement2.component';
import { FormAgreement3Component } from './_components/form-agreement3/form-agreement3.component';
import { FormAgreement4Component } from './_components/form-agreement4/form-agreement4.component';

@NgModule({
  declarations: [
    AppComponent,
    TopBarComponent,
    FooterComponent,
    ContactComponent,
    StudentRegistrationComponent,
    PrivacyStatementComponent,
    MembershipRegistrationComponent,
    MemberConsentComponent,
    LawsAgreementsComponent,
    FaqsComponent,
    HomeComponent,
    AboutUsComponent,
    ServicesComponent,
    DashboardComponent,
    AddComponent,
    EditDeleteComponent,
    LoginComponent,
    MemberBenefitsComponent,
    VotesComponent,
    SectorAgreementComponent,
    NewsComponent,
    AddComComponent,
    EditComComponent,
    UpdateAgreementComponent,
    UpdateLinksComponent,
    ModalComponent,
    VotationsComponent,
    UsersComponent,
    TopBarDashboardComponent,
    ComunicateFormComponent,
    FormNavComponent,
    FormAboutComponent,
    FormFaqsComponent,
    FormContactComponent,
    FormExternalLinksComponent,
    OffersComponent,
    FormBenefitsComponent,
    FormPrivacyComponent,
    FormConsentComponent,
    FormAgreement1Component,
    FormAgreement2Component,
    FormAgreement3Component,
    FormAgreement4Component,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    FlexLayoutModule,
    MatMenuModule,
    MatExpansionModule,
    CommonModule,
    MatExpansionModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    HttpClientModule,
    SweetAlert2Module,
    MatDialogModule,
    NgxMatFileInputModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
