import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface Agreement {
  id: number;
  title: string;
  link1: string;
  link2: string;
  pdf: string;
}

@Injectable({
  providedIn: 'root',
})
export class AgreementService {
  constructor(private http: HttpClient) {}

  async getAgreements(
    agreementSelected: number
  ): Promise<Observable<Agreement>> {
    const headers: Record<string,string> = {
      'Authorization': sessionStorage.getItem('token')!
    }
    const path = this.getGetterPath(agreementSelected);
    return this.http.get<Agreement>(path, {headers});
  }

  async getAgreementsImg(
    agreementSelected: number
  ): Promise<Observable<Agreement[]>> {
    const headers: Record<string,string> = {
      'Authorization': sessionStorage.getItem('token')!
    }
    const path = this.getGetterPdfPath(agreementSelected);
    const result = this.http.get<Agreement[]>(path, {headers});
    result.subscribe((response) => {
      // console.log('response', response);
    });
    return result;
  }

  async setAgreement(
    agreementSelected: number,
    data: any
  ): Promise<Observable<Agreement>> {
    const headers: Record<string,string> = {
      'Authorization': sessionStorage.getItem('token')!
    }
    const formData: FormData = new FormData();
    formData.append('id', '' + data.id);
    formData.append('title', data.title);
    formData.append('link1', data.link1);
    formData.append('link2', data.link2);
    formData.append('pdf', data.pdf);

    const path = this.getSetterPath(agreementSelected);
    return this.http.put<Agreement>(path, formData, {headers});
  }

  private getGetterPath(agreementSelected: number): string {
    let path = '';
    switch (agreementSelected) {
      case 1:
        path = environment.apiBasePath + 'agreement/getAgreement1';
        break;
      case 2:
        path = environment.apiBasePath + 'agreement/getAgreement2';
        break;
      case 3:
        path = environment.apiBasePath + 'agreement/getAgreement3';
        break;
      case 4:
        path = environment.apiBasePath + 'agreement/getAgreement4';
        break;
      default:
        path = environment.apiBasePath + 'agreement/getAgreement1';
    }
    return path;
  }

  private getGetterPdfPath(agreementSelected: number): string {
    let path = '';
    switch (agreementSelected) {
      case 1:
        path = environment.apiBasePath + 'agreement/getAgreement1Pdf';
        break;
      case 2:
        path = environment.apiBasePath + 'agreement/getAgreement2Pdf';
        break;
      case 3:
        path = environment.apiBasePath + 'agreement/getAgreement3Pdf';
        break;
      case 4:
        path = environment.apiBasePath + 'agreement/getAgreement4Pdf';
        break;
      default:
        path = environment.apiBasePath + 'agreement/getAgreement1Pdf';
    }
    return path;
  }

  private getSetterPath(agreementSelected: number): string {
    let path = '';
    switch (agreementSelected) {
      case 1:
        path = environment.apiBasePath + 'agreement/editAgreement1';
        break;
      case 2:
        path = environment.apiBasePath + 'agreement/editAgreement2';
        break;
      case 3:
        path = environment.apiBasePath + 'agreement/editAgreement3';
        break;
      case 4:
        path = environment.apiBasePath + 'agreement/editAgreement4';
        break;
      default:
        path = environment.apiBasePath + 'agreement/editAgreement1';
    }
    return path;
  }
}
