import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
  Agreement,
  AgreementService,
} from 'src/app/_services/agreement.service';

@Component({
  selector: 'app-sector-agreement',
  templateUrl: './sector-agreement.component.html',
  styleUrls: ['./sector-agreement.component.css'],
})
export class SectorAgreementComponent implements OnInit {
  @ViewChild('pdfFrame') documentElement!: ElementRef;
  @ViewChild('pdfFrame2') documentElement2!: ElementRef;

  agreements: any[] = [];
  agreementSelected: number = 1;
  isLoaded: boolean = false;

  constructor(
    private agreementService: AgreementService,
    private sanitizer: DomSanitizer
  ) {}

  async ngOnInit(): Promise<void> {
    (await this.agreementService.getAgreementsImg(1)).subscribe((data) => {
      this.agreements = data;
      this.isLoaded = true;

      setTimeout(() => {
        const byteArray = new Uint8Array(
          atob(this.agreements[0].pdf)
            .split('')
            .map((char) => char.charCodeAt(0))
        );
        const blob = new Blob([byteArray], { type: 'application/pdf' });

        const url = window.URL.createObjectURL(blob);

        // document.querySelector('iframe')!.src = url;

        this.documentElement.nativeElement.setAttribute('src', url);
      }, 200);
    });
  }

  async onNavButtonClick(agreementSelected: number): Promise<void> {
    this.isLoaded = false;
    this.agreementSelected = agreementSelected;
    (await this.agreementService.getAgreementsImg(agreementSelected)).subscribe(
      async (data) => {
        this.agreements = data;
        this.isLoaded = true;
        setTimeout(() => {
          const byteArray = new Uint8Array(
            atob(this.agreements[0].pdf)
              .split('')
              .map((char) => char.charCodeAt(0))
          );
          const blob = new Blob([byteArray], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);

          document.querySelector('iframe')!.src = url;

          this.documentElement.nativeElement.setAttribute('src', url);
        }, 200);
      }
    );
  }
}
