import { Component, OnInit } from '@angular/core';
import {
  GestionService,
  PrivacyConsent,
} from 'src/app/_services/gestion.service';

@Component({
  selector: 'app-privacy-statement',
  templateUrl: './privacy-statement.component.html',
  styleUrls: ['./privacy-statement.component.css'],
})
export class PrivacyStatementComponent implements OnInit {
  isLoaded: boolean = false;
  privacy: PrivacyConsent[] = [];

  constructor(private gestionService: GestionService) {}

  async ngOnInit(): Promise<void> {
    (await this.gestionService.getPrivacy()).subscribe((data) => {
      this.privacy = data;
      this.isLoaded = true;
    });
  }
}
