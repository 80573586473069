<div class="add-form" *ngIf="isLoaded">
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)" >
        <div class="form-input">
            <p>
                <mat-form-field appearance="outline" [style.width.px]=200>
                <mat-label>{{ formValue[0].service1 }}</mat-label>

                    <input type="text" matInput formControlName="service1" required>
                    <!-- <mat-icon matSuffix>service1</mat-icon> -->
                    <mat-error *ngIf="formGroup.get('service1')?.errors && formGroup.get('service1')?.errors?.['required']">Título obligatorio.</mat-error>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="outline" [style.width.px]=200>
                <mat-label>{{ formValue[0].service2 }}</mat-label>

                    <input type="text" matInput formControlName="service2" required>
                    <!-- <mat-icon matSuffix>service2</mat-icon> -->
                    <mat-error *ngIf="formGroup.get('service2')?.errors && formGroup.get('service2')?.errors?.['required']">Opcion 1 obligatoria.</mat-error>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="outline" [style.width.px]=200>
                <mat-label>{{ formValue[0].service3 }}</mat-label>

                    <input type="text" matInput formControlName="service3" required>
                    <!-- <mat-icon matSuffix>service3</mat-icon> -->
                    <mat-error *ngIf="formGroup.get('service3')?.errors && formGroup.get('service3')?.errors?.['required']">Opcion 2 obligatoria.</mat-error>
                </mat-form-field>
            </p>

            <p>
                <mat-form-field appearance="outline" [style.width.px]=200>
                <mat-label>{{ formValue[0].service4 }}</mat-label>

                    <input type="text" matInput formControlName="service4" required>
                    <!-- <mat-icon matSuffix>service4</mat-icon> -->
                    <mat-error *ngIf="formGroup.get('service4')?.errors && formGroup.get('service4')?.errors?.['required']">Opcion 2 obligatoria.</mat-error>
                </mat-form-field>
            </p>
           
        </div>
        <div fxLayout="row wrap">
            <div fxFlex="50%" fxFlex.md="50%" fxFlex.xs="100%" fxFlex.sm="100%">
                <p>
                    <mat-form-field class="paragraph-size" appearance="outline">
                    <mat-label>Primer Párrafo</mat-label>
    
                        <textarea type="text" matInput formControlName="paragraph1" required class="textarea-width"></textarea>
                        <!-- <mat-icon matSuffix>paragraph1</mat-icon> -->
                        <mat-error *ngIf="formGroup.get('paragraph1')?.errors && formGroup.get('paragraph1')?.errors?.['required']">Título obligatorio.</mat-error>
                    </mat-form-field>
                </p>
                    <p>
  <!-- New Number Input -->
  <mat-form-field  appearance="outline">
    <mat-label>Número</mat-label>
    <input type="number" matInput formControlName="numberInput" required>
    <mat-error *ngIf="formGroup.get('numberInput')?.errors && formGroup.get('numberInput')?.errors?.['required']">
      Número obligatorio.
    </mat-error>
  </mat-form-field>
</p>
            </div>
            <div fxFlex="50%" fxFlex.md="50%" fxFlex.xs="100%" fxFlex.sm="100%">
               
            <p>
                <mat-form-field class="paragraph-size" appearance="outline">
                <mat-label>Segundo Párrafo</mat-label>

                    <textarea type="text" matInput formControlName="paragraph2" required class="textarea-width"></textarea>
                    <!-- <mat-icon matSuffix>paragraph2</mat-icon> -->
                    <mat-error *ngIf="formGroup.get('paragraph2')?.errors && formGroup.get('paragraph2')?.errors?.['required']">Opcion 1 obligatoria.</mat-error>
                </mat-form-field>
            </p>
            </div>
        </div>
        <div class="action">
            <div class="button">
                <button class="send" type="submit" mat-button color="success">
                    Actualizar
                </button>
            </div>
            <div class="spinner">
                <mat-spinner *ngIf="isSpinner" [diameter]="30"></mat-spinner>
            </div>
        </div>
        
    </form>
</div>