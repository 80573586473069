<div class="about-us">
  <div class="carousel-last">
    <div class="carousel-last-images" [ngStyle]="{'transform': translateHtml}">
      <img src="../../../assets/about.png">
      <img src="../../../assets/about2.png">
      <img src="../../../assets/muestra.png">
      <img src="../../../assets/about4.png">
    </div>
    <button class="carousel-button-previous-last" id="next" (click)="onPreviousClick()">
      < </button>

        <button class="carousel-button-next-last" id="next" (click)="onNextClick()">></button>
  </div>
</div>

<div id="about"></div>
<div class="about-us-second" *ngIf="isLoaded">
  <h1 class="title">Sobre Nosotros
  </h1>
  <div fxLayout="row wrap" fxLayoutGap="30px grid">
    <div class="service-card" fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="50%">
      {{ aboutInfo[0].paragraph1 }}
    </div>
    <div class="service-card" fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="50%">
      {{ aboutInfo[0].paragraph2 }}
    </div>
  </div>
  <div class="counter-div">
    <div class="container">
      <div class="row">
        <div class="col-md-3 col-sm-6">
          <div class="counter blue">
            <div class="counter-icon">
              <mat-icon>people</mat-icon>
            </div>
            <h3 class="counter-text">NUESTRA COMUNIDAD</h3>
            <span class="counter-value">{{ counter }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>