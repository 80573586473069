import { AfterViewInit, Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ENTER } from '@angular/cdk/keycodes';
import { GestionService, NavTitles } from 'src/app/_services/gestion.service';

@Component({
  selector: 'app-form-nav',
  templateUrl: './form-nav.component.html',
  styleUrls: ['./form-nav.component.css'],
})
export class FormNavComponent implements OnInit {
  isLoaded: boolean = false;
  isSpinner: boolean = false;
  formGroup!: UntypedFormGroup;
  addOnBlur = true;
  separatorKeysCodes = [ENTER] as const;

  formValue: NavTitles[] = [];
  constructor(
    private formBuilder: UntypedFormBuilder,
    private gestionService: GestionService
  ) {}

  async ngOnInit(): Promise<void> {
    (await this.gestionService.getNavTitles()).subscribe((data) => {
      this.formValue = data;
      this.formGroup = this.formBuilder.group({
        title1: [this.formValue[0].title1, Validators.required],
        title2: [this.formValue[0].title2, Validators.required],
        title3: [this.formValue[0].title3, Validators.required],
        title4: [this.formValue[0].title4, Validators.required],
        title5: [this.formValue[0].title5, Validators.required],
        title6: [this.formValue[0].title6, Validators.required],
        title7: [this.formValue[0].title7, Validators.required],
        title8: [this.formValue[0].title8, Validators.required],
        title9: [this.formValue[0].title9, Validators.required],
        title10: [this.formValue[0].title10, Validators.required],
        title11: [this.formValue[0].title11, Validators.required],
      });
      this.isLoaded = true;
    });
  }

  async onSubmit(input: NavTitles): Promise<void> {
    if (this.formGroup.valid) {
      this.isSpinner = true;
      input.id = this.formValue[0].id;
      (await this.gestionService.setNavTitles(input)).subscribe((data) => {
        this.formGroup = this.formBuilder.group({
          title1: [data.title1, Validators.required],
          title2: [data.title2, Validators.required],
          title3: [data.title3, Validators.required],
          title4: [data.title4, Validators.required],
          title5: [data.title5, Validators.required],
          title6: [data.title6, Validators.required],
          title7: [data.title7, Validators.required],
          title8: [data.title8, Validators.required],
          title9: [data.title9, Validators.required],
          title10: [data.title10, Validators.required],
          title11: [data.title11, Validators.required],
        });
        this.isSpinner = false;
      });
    }
  }
}
