<div *ngIf="!isVisible && isLoaded" fxLayout="row wrap" fxLayoutGap="grid" class="container">
  <div fxFlex="20%" class="first">
    <a mat-button (click)="goHome()"><img class="logo" src="../../../assets/logo-definitivo.jpeg" alt="logo" />
    </a>
  </div>
  <div fxFlex="60%" class="second">
    <div *ngIf="!isLogged">
      <button class="nav-desktop-button" mat-button (click)="goToPage('aboutUs')">{{navTitles[0].title1}}</button>
      <button class="nav-desktop-button" mat-button (click)="goToPage('services')">{{navTitles[0].title2}}</button>
      <button class="nav-desktop-button" mat-button (click)="goToPage('contact')">{{navTitles[0].title3}}</button>
      <button class="nav-desktop-button" mat-button (click)="goToPage('benefits')">{{navTitles[0].title4}}</button>
      <button *ngIf="!isLogged" class="afiliate" mat-button (click)="goToPage('membershipRegistration')">
        {{navTitles[0].title5}}
      </button>
    </div>

    <div *ngIf="isLogged">
      <button class="nav-desktop-button" mat-button (click)="goToPage('news')">{{navTitles[0].title6}}</button>
      <button class="nav-desktop-button" mat-button (click)="goToPage('votes')">{{navTitles[0].title7}}</button>
      <button class="nav-desktop-button" mat-button
        (click)="goToPage('sector-agreement')">{{navTitles[0].title8}}</button>
      <button class="nav-desktop-button" mat-button (click)="goToPage('contact')">{{navTitles[0].title9}}</button>

      <button *ngIf="isLogged" mat-button [matMenuTriggerFor]="socio">{{navTitles[0].title10}}</button>
      <mat-menu #socio="matMenu" yPosition="below">
        <button mat-menu-item (click)="goToPage('offers')">{{navTitles[0].title11}}</button>
        <button mat-menu-item (click)="goToPage('benefits')">{{navTitles[0].title4}}</button>
      </mat-menu>
    </div>
  </div>
  <div fxFlex="20%" class="third">
    <div *ngIf="!isLogged">
      <button class="session" mat-button (click)="goToPage('login')">
        <strong>INICIAR SESIÓN</strong>
      </button>

    </div>
    <div *ngIf="isLogged" class="logged-options">
      <button class="session" mat-button (click)="goOut()"><strong>CERRAR SESIÓN</strong> </button>
      <button *ngIf="isAdmin" class="setting" mat-button (click)="goToPage('dashboard')"><mat-icon>settings</mat-icon></button>
    </div>
  </div>
</div>

<div *ngIf="isVisible && isLoaded" fxLayout="row wrap" fxLayoutGap="grid" class="container">
  <div fxFlex="50%" class="first">
    <a mat-button (click)="goHome()"><img class="logo" src="../../../assets/logo-definitivo.jpeg" alt="logo" />
    </a>
  </div>
  <div fxFlex="50%" class="third">
    <button mat-button (click)="openMenu()">
      <mat-icon>subject</mat-icon>
    </button>
  </div>
</div>

<div class="second-nav" *ngIf="isVisible && menu">
  <mat-accordion multi>
    <div *ngIf="!isLogged">
      <mat-expansion-panel (click)="goToPage('aboutUs')" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <strong>{{navTitles[0].title1}}</strong>
          </mat-panel-title>
        </mat-expansion-panel-header>
      </mat-expansion-panel>
      <mat-expansion-panel (click)="goToPage('services')" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <strong>{{navTitles[0].title2}}</strong>
          </mat-panel-title>
        </mat-expansion-panel-header>
      </mat-expansion-panel>
      <mat-expansion-panel (click)="goToPage('contact')" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <strong>{{navTitles[0].title3}}</strong>
          </mat-panel-title>
        </mat-expansion-panel-header>
      </mat-expansion-panel>
      <mat-expansion-panel (click)="goToPage('benefits')" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <strong>{{navTitles[0].title4}}</strong>
          </mat-panel-title>
        </mat-expansion-panel-header>
      </mat-expansion-panel>
      <mat-expansion-panel (click)="goToPage('membershipRegistration')" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <strong>{{navTitles[0].title5}}</strong>
          </mat-panel-title>
        </mat-expansion-panel-header>
      </mat-expansion-panel>
    </div>
    <div *ngIf="isLogged">
      <mat-expansion-panel (click)="goToPage('news')" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <strong>{{navTitles[0].title6}}</strong>
          </mat-panel-title>
        </mat-expansion-panel-header>
      </mat-expansion-panel>
      <mat-expansion-panel (click)="goToPage('votes')" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <strong>{{navTitles[0].title7}}</strong>
          </mat-panel-title>
        </mat-expansion-panel-header>
      </mat-expansion-panel>
      <mat-expansion-panel (click)="goToPage('sector-agreement')" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <strong>{{navTitles[0].title8}}</strong>
          </mat-panel-title>
        </mat-expansion-panel-header>
      </mat-expansion-panel>
      <mat-expansion-panel (click)="goToPage('contact')" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <strong>{{navTitles[0].title9}}</strong>
          </mat-panel-title>
        </mat-expansion-panel-header>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <strong>{{navTitles[0].title10}}</strong>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="response">
          <mat-card class="mobNav mat-elevation-z0"
            (click)="goToPage('offers')"><strong>{{navTitles[0].title11}}</strong></mat-card>
          <mat-card class="mobNav mat-elevation-z0"
            (click)="goToPage('benefits')"><strong>{{navTitles[0].title4}}</strong></mat-card>

        </div>
      </mat-expansion-panel>


    </div>

    <mat-expansion-panel *ngIf="!isLogged" (click)="goToPage('login')" hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <strong>INICIAR SESIÓN</strong>
        </mat-panel-title>
      </mat-expansion-panel-header>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="isLogged" (click)="goOut()" hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <strong>CERRAR SESIÓN</strong>
        </mat-panel-title>
      </mat-expansion-panel-header>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="isAdmin" (click)="goToPage('dashboard')" hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon class="icon-display">settings</mat-icon>
        </mat-panel-title>
      </mat-expansion-panel-header>
    </mat-expansion-panel>



  </mat-accordion>
</div>