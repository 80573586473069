import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { HomeComponent } from './pages/home/home.component';
import { LawsAgreementsComponent } from './pages/laws-agreements/laws-agreements.component';
import { LoginComponent } from './pages/login/login.component';
import { MemberBenefitsComponent } from './pages/member-benefits/member-benefits.component';
import { MemberConsentComponent } from './pages/member-consent/member-consent.component';
import { MembershipRegistrationComponent } from './pages/membership-registration/membership-registration.component';
import { NewsComponent } from './pages/news/news.component';
import { OffersComponent } from './pages/offers/offers.component';
import { PrivacyStatementComponent } from './pages/privacy-statement/privacy-statement.component';
import { SectorAgreementComponent } from './pages/sector-agreement/sector-agreement.component';
import { StudentRegistrationComponent } from './pages/student-registration/student-registration.component';
import { VotesComponent } from './pages/votes/votes.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'home/:scrollTo', component: HomeComponent },

  { path: 'laws', component: LawsAgreementsComponent },
  { path: 'member-consent', component: MemberConsentComponent },
  { path: 'privacy-statement', component: PrivacyStatementComponent },
  { path: 'students-registration', component: StudentRegistrationComponent },
  { path: 'login', component: LoginComponent },
  { path: 'benefits', component: MemberBenefitsComponent },
  {
    path: 'membership-registration',
    component: MembershipRegistrationComponent,
  },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'news', component: NewsComponent },
  { path: 'votes', component: VotesComponent },
  { path: 'sector-agreement', component: SectorAgreementComponent },
  { path: 'offers', component: OffersComponent },
  { path: '**', component: HomeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
