<mat-card class="container-form">
  <h1 class="title-left">Editar o Eliminar Noticias</h1>
  <div class="dashboard centrado" fxLayout="row wrap">
    <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="100%">
      <mat-card class="formulario-card-edit">
        <h2>Titulo</h2>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit eum
          necessitatibus consequatur sed quia corrupti veniam amet nemo?
          Reiciendis delectus doloribus dolore eveniet. Esse itaque non
          exercitationem veniam odit numquam.
        </p>
        <a href="http://">enlace a un enlace que contiene info</a> <br />
        <br />
        <button (click)="openModal()" class="edit" mat-raised-button>
          Editar
        </button>
        &nbsp;
        <button class="delete" mat-raised-button>Eliminar</button>
      </mat-card>
      <mat-card class="formulario-card-edit">
        <h2>Titulo</h2>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit eum
          necessitatibus consequatur sed quia corrupti veniam amet nemo?
          Reiciendis delectus doloribus dolore eveniet. Esse itaque non
          exercitationem veniam odit numquam.
        </p>
        <a href="http://">enlace a un enlace que contiene info</a> <br />
        <br />
        <button class="edit" mat-raised-button>Editar</button> &nbsp;
        <button class="delete" mat-raised-button>Eliminar</button>
      </mat-card>
      <mat-card class="formulario-card-edit">
        <h2>Titulo</h2>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit eum
          necessitatibus consequatur sed quia corrupti veniam amet nemo?
          Reiciendis delectus doloribus dolore eveniet. Esse itaque non
          exercitationem veniam odit numquam.
        </p>
        <a href="http://">enlace a un enlace que contiene info</a> <br />
        <br />
        <button class="edit" mat-raised-button>Editar</button> &nbsp;
        <button class="delete" mat-raised-button>Eliminar</button>
      </mat-card>
    </div>
    <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="100%">
      <mat-card class="formulario-card-edit">
        <h2>Titulo</h2>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit eum
          necessitatibus consequatur sed quia corrupti veniam amet nemo?
          Reiciendis delectus doloribus dolore eveniet. Esse itaque non
          exercitationem veniam odit numquam.
        </p>
        <a href="http://">enlace a un enlace que contiene info</a> <br />
        <br />
        <button class="edit" mat-raised-button>Editar</button> &nbsp;
        <button class="delete" mat-raised-button>Eliminar</button>
      </mat-card>
      <mat-card class="formulario-card-edit">
        <h2>Titulo</h2>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit eum
          necessitatibus consequatur sed quia corrupti veniam amet nemo?
          Reiciendis delectus doloribus dolore eveniet. Esse itaque non
          exercitationem veniam odit numquam.
        </p>
        <a href="http://">enlace a un enlace que contiene info</a> <br />
        <br />
        <button class="edit" mat-raised-button>Editar</button> &nbsp;
        <button class="delete" mat-raised-button>Eliminar</button>
      </mat-card>
      <mat-card class="formulario-card-edit">
        <h2>Titulo</h2>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit eum
          necessitatibus consequatur sed quia corrupti veniam amet nemo?
          Reiciendis delectus doloribus dolore eveniet. Esse itaque non
          exercitationem veniam odit numquam.
        </p>
        <a href="http://">enlace a un enlace que contiene info</a> <br />
        <br />
        <button class="edit" mat-raised-button>Editar</button> &nbsp;
        <button class="delete" mat-raised-button>Eliminar</button>
      </mat-card>
    </div>
    <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="100%">
      <mat-card class="formulario-card-edit">
        <h2>Titulo</h2>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit eum
          necessitatibus consequatur sed quia corrupti veniam amet nemo?
          Reiciendis delectus doloribus dolore eveniet. Esse itaque non
          exercitationem veniam odit numquam.
        </p>
        <a href="http://">enlace a un enlace que contiene info</a> <br />
        <br />
        <button class="edit" mat-raised-button>Editar</button> &nbsp;
        <button class="delete" mat-raised-button>Eliminar</button>
      </mat-card>
      <mat-card class="formulario-card-edit">
        <h2>Titulo</h2>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit eum
          necessitatibus consequatur sed quia corrupti veniam amet nemo?
          Reiciendis delectus doloribus dolore eveniet. Esse itaque non
          exercitationem veniam odit numquam.
        </p>
        <a href="http://">enlace a un enlace que contiene info</a> <br />
        <br />
        <button class="edit" mat-raised-button>Editar</button> &nbsp;
        <button class="delete" mat-raised-button>Eliminar</button>
      </mat-card>
      <mat-card class="formulario-card-edit">
        <h2>Titulo</h2>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit eum
          necessitatibus consequatur sed quia corrupti veniam amet nemo?
          Reiciendis delectus doloribus dolore eveniet. Esse itaque non
          exercitationem veniam odit numquam.
        </p>
        <a href="http://">enlace a un enlace que contiene info</a> <br />
        <br />
        <button class="edit" mat-raised-button>Editar</button> &nbsp;
        <button class="delete" mat-raised-button>Eliminar</button>
      </mat-card>
    </div>
  </div>
</mat-card>

<app-modal *ngIf="isModal"></app-modal>
