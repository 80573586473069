<div class="first">
    <div>
      <h1>Beneficios para Miembros</h1>
      <hr>
      <div *ngIf="isLoaded">
        <p *ngIf="benefits[0].paragraph1" class="first-p">
          {{ benefits[0].paragraph1 }}
        </p>
        <p *ngIf="benefits[0].paragraph2" class="first-p">
          {{ benefits[0].paragraph2 }}
        </p>
        <ul>
          <li *ngIf="benefits[0].item1">
            {{ benefits[0].item1 }}
          </li>
          <li *ngIf="benefits[0].item2">
            {{ benefits[0].item2 }}
          </li>
          <li *ngIf="benefits[0].item3">
            {{ benefits[0].item3 }}
          </li>
          <li *ngIf="benefits[0].item4">
            {{ benefits[0].item4 }}
          </li>
          <li *ngIf="benefits[0].item5">
            {{ benefits[0].item5 }}
          </li>
        </ul>
      </div>
    </div>
  </div>
  