<div class="add-form" *ngIf="isLoaded">
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)" >
        <div fxLayout="row wrap">
            <div fxFlex="50%" fxFlex.md="50%" fxFlex.xs="100%" fxFlex.sm="100%">
                <p>
                    <mat-form-field class="paragraph-size" appearance="outline" >
                        <mat-label>Primer Párrafo</mat-label>
                        <textarea type="text" matInput formControlName="paragraph1" required class="textarea-width"></textarea>
                        <!-- <mat-icon matSuffix>paragraph1</mat-icon> -->
                        <mat-error *ngIf="formGroup.get('paragraph1')?.errors && formGroup.get('paragraph1')?.errors?.['required']">Título obligatorio.</mat-error>
                    </mat-form-field>
                </p>
            </div>
            <div fxFlex="50%" fxFlex.md="50%" fxFlex.xs="100%" fxFlex.sm="100%">
                <p>
                    <mat-form-field class="paragraph-size" appearance="outline" >
                        <mat-label>Segundo Párrafo</mat-label>
                        <textarea type="text" matInput formControlName="paragraph2" required class="textarea-width"></textarea>
                        <!-- <mat-icon matSuffix>paragraph2</mat-icon> -->
                        <mat-error *ngIf="formGroup.get('paragraph2')?.errors && formGroup.get('paragraph2')?.errors?.['required']">Opcion 1 obligatoria.</mat-error>
                    </mat-form-field>
                </p>
            </div>
        </div>
        <div class="form-input">
            <p>
                <mat-form-field appearance="outline" [style.width.px]=200>
                <mat-label>Beneficio 1</mat-label>
                    <input type="text" matInput formControlName="item1" required>
                    <!-- <mat-icon matSuffix>item1</mat-icon> -->
                    <mat-error *ngIf="formGroup.get('item1')?.errors && formGroup.get('item1')?.errors?.['required']">Título obligatorio.</mat-error>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="outline" [style.width.px]=200>
                <mat-label>Beneficio 2</mat-label>
                    <input type="text" matInput formControlName="item2" required>
                    <!-- <mat-icon matSuffix>item2</mat-icon> -->
                    <mat-error *ngIf="formGroup.get('item2')?.errors && formGroup.get('item2')?.errors?.['required']">Opcion 1 obligatoria.</mat-error>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="outline" [style.width.px]=200>
                <mat-label>Beneficio 3</mat-label>
                    <input type="text" matInput formControlName="item3" required>
                    <!-- <mat-icon matSuffix>item3</mat-icon> -->
                    <mat-error *ngIf="formGroup.get('item3')?.errors && formGroup.get('item3')?.errors?.['required']">Opcion 2 obligatoria.</mat-error>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="outline" [style.width.px]=200>
                <mat-label>Beneficio 4</mat-label>
                    <input type="text" matInput formControlName="item4" required>
                    <!-- <mat-icon matSuffix>item4</mat-icon> -->
                    <mat-error *ngIf="formGroup.get('item4')?.errors && formGroup.get('item4')?.errors?.['required']">Opcion 2 obligatoria.</mat-error>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="outline" [style.width.px]=200>
                <mat-label>Beneficio 5</mat-label>
                    <input type="text" matInput formControlName="item5" required>
                    <!-- <mat-icon matSuffix>item5</mat-icon> -->
                    <mat-error *ngIf="formGroup.get('item5')?.errors && formGroup.get('item5')?.errors?.['required']">Opcion 2 obligatoria.</mat-error>
                </mat-form-field>
            </p>
        </div>
        <div class="action">
            <div class="button">
                <button class="send" type="submit" mat-button color="success">
                    Actualizar
                </button>
            </div>
            <div class="spinner">
                <mat-spinner *ngIf="isSpinner" [diameter]="30"></mat-spinner>
            </div>
        </div>  
    </form>
</div>