import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Benefits, GestionService } from 'src/app/_services/gestion.service';

@Component({
  selector: 'app-form-benefits',
  templateUrl: './form-benefits.component.html',
  styleUrls: ['./form-benefits.component.css'],
})
export class FormBenefitsComponent implements OnInit {
  isSpinner: boolean = false;
  isLoaded: boolean = false;
  formGroup!: UntypedFormGroup;
  formValue: Benefits[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private gestionService: GestionService
  ) {}

  async ngOnInit(): Promise<void> {
    (await this.gestionService.getBenefits()).subscribe((data) => {
      this.formValue = data;
      this.formGroup = this.formBuilder.group({
        paragraph1: [this.formValue[0].paragraph1, Validators.required],
        paragraph2: [this.formValue[0].paragraph2, Validators.required],
        item1: [this.formValue[0].item1, Validators.required],
        item2: [this.formValue[0].item2, Validators.required],
        item3: [this.formValue[0].item3, Validators.required],
        item4: [this.formValue[0].item4, Validators.required],
        item5: [this.formValue[0].item5, Validators.required],
      });
      this.isLoaded = true;
    });
  }

  async onSubmit(input: Benefits): Promise<void> {
    if (this.formGroup.valid) {
      this.isSpinner = true;
      input.id = this.formValue[0].id;
      (await this.gestionService.setBenefits(input)).subscribe((data) => {
        this.formGroup = this.formBuilder.group({
          paragraph1: [data.paragraph1, Validators.required],
          paragraph2: [data.paragraph2, Validators.required],
          item1: [data.item1, Validators.required],
          item2: [data.item2, Validators.required],
          item3: [data.item3, Validators.required],
          item4: [data.item4, Validators.required],
          item5: [data.item5, Validators.required],
        });
        this.isSpinner = false;
      });
    }
  }
}
