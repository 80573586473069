import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { About, GestionService } from 'src/app/_services/gestion.service';

@Component({
  selector: 'app-form-about',
  templateUrl: './form-about.component.html',
  styleUrls: ['./form-about.component.css'],
})
export class FormAboutComponent implements OnInit {
  isSpinner: boolean = false;
  isLoaded: boolean = false;
  formGroup!: UntypedFormGroup;
  formValue: About[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private gestionService: GestionService
  ) {}

  async ngOnInit(): Promise<void> {
    (await this.gestionService.getAbout()).subscribe((data) => {
      this.formValue = data;
      this.formGroup = this.formBuilder.group({
        service1: [this.formValue[0].service1, Validators.required],
        service2: [this.formValue[0].service2, Validators.required],
        service3: [this.formValue[0].service3, Validators.required],
        service4: [this.formValue[0].service4, Validators.required],
        paragraph1: [this.formValue[0].paragraph1, Validators.required],
        paragraph2: [this.formValue[0].paragraph2, Validators.required],
        numberInput: [this.formValue[0].numberInput, Validators.required],
      });
      this.isLoaded = true;
    });
  }

  async onSubmit(input: About): Promise<void> {
    if (this.formGroup.valid) {
      this.isSpinner = true;
      input.id = this.formValue[0].id;
      (await this.gestionService.setAbout(input)).subscribe((data) => {
        this.formGroup = this.formBuilder.group({
          service1: [data.service1, Validators.required],
          service2: [data.service2, Validators.required],
          service3: [data.service3, Validators.required],
          service4: [data.service4, Validators.required],
          paragraph1: [data.paragraph1, Validators.required],
          paragraph2: [data.paragraph2, Validators.required],
          numberInput: [this.formValue[0].numberInput, Validators.required],
        });
        this.isSpinner = false;
      });
    }
  }
}
