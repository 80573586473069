import { AfterViewInit, Component } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Params,
  Router,
} from '@angular/router';
import { Event } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'alm-front-end';
  currentRoute: string = '';
  page: string = '';
  constructor(private router: Router) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
      }
      if (event instanceof NavigationEnd) {
        // Hide progress spinner or progress bar
        this.currentRoute = event.url;
        this.page = this.currentRoute.replace('/', '');
      }

      if (event instanceof NavigationError) {
        // Hide progress spinner or progress bar
        // Present error to user
        // console.log(event.error);
      }
    });
  }

  getChartsData() {}

  changepage(params: Params): void {
    // console.log('PARAMS,', params);
  }
}
