<div class="first" *ngIf="isLoaded">
  <div>
    <h1>Comunicados</h1>
    <hr>

  </div>
  <div class="content">
    <mat-card class="cards " *ngFor="let comunicate of comunicates" >
      <div>
        <h2 class="voteTitle">{{ comunicate.title }}</h2>  
        <div fxLayout="row wrap" fxLayoutGap="grid">
          <div class="img-content" fxFlex="40%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="100%">
            <img class="img-news" [src]="comunicate.img">
          </div>
          <div class="desc-content" fxFlex="60%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="100%">
            <p>
              {{ comunicate.description }}
            </p>
          </div>
        </div>
        <p>
          <a class="link" target="_blank" href={{comunicate.link}}>Saber mas</a>
        </p>
      </div>
    </mat-card>
  </div>
</div>