import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserModel } from '../_models/user.model';

export interface UserData {
  id: number;
  name: string;
  lastNameOne: string;
  lastNameTwo: string;
  email: string;
  phone: string;
  country: string;
  linkedin: string;
  licenseType: string;
  licenseNumber: string;
  licenseCustom: string;
  n_id: string;
  business: string;
  password: string;
  validate: number;
  lostPassword: number;
}

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private http: HttpClient) {}

  user!: UserModel;

  async getUsers(): Promise<Observable<UserData[]>> {
    const headers: Record<string,string> = {
      'Authorization': sessionStorage.getItem('token')!
    }
    const path = environment.apiBasePath + 'users/getUsers';
    return this.http.get<UserData[]>(path, {headers});
  }

  async addUser(input: any): Promise<Observable<UserData[]>> {
  //  const headers: Record<string,string> = {
   //   'Authorization': sessionStorage.getItem('token')!
   // }
    const body = input;
    const path = environment.apiBasePath + 'users/addUser';
    return this.http.post<UserData[]>(path, body);
  }

  async deleteUser(id: number) {
    const headers: Record<string,string> = {
      'Authorization': sessionStorage.getItem('token')!
    }
    const path = `${environment.apiBasePath}users/deleteUser/${id}`;
    const result = this.http.delete<UserData>(path, {headers});
    result.subscribe((response) => {
      // console.log('response', response);
    });
  }

  async loginUser(input: any): Promise<Observable<any>> {
    const body = input;
    const path = environment.apiBasePath + 'users/loginUser';
    return this.http.post<any>(path, body);
  }

  async changeUserValidation(id: number): Promise<Observable<UserData>> {
    const headers: Record<string,string> = {
      'Authorization': sessionStorage.getItem('token')!
    }
    const path = environment.apiBasePath + 'users/changeUserValidation';
    return this.http.put<UserData>(path, id, {headers});
  }

  async changeLostPassword(email: string): Promise<Observable<UserData>> {
    const path = environment.apiBasePath + 'users/changeLostPass';
    return this.http.put<UserData>(path, email);
  }

  async changePassword(input: any): Promise<Observable<UserData>> {
    const path = environment.apiBasePath + 'users/changePassword';
    return this.http.put<UserData>(path, input);
  }
}
