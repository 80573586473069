<div class="add-form" *ngIf="isLoaded">
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)" class="form-input"
        enctype="multipart/form-data">
        <p>
            <mat-form-field appearance="outline" [style.width.px]="300">
                <mat-label>Título</mat-label>

                <input type="text" matInput formControlName="title" />
                <!-- <mat-icon matSuffix>title</mat-icon> -->
                <mat-error *ngIf="formGroup.get('title')?.errors && formGroup.get('title')?.errors?.['required']">Título
                    obligatorio.</mat-error>
            </mat-form-field>
        </p>
        <p class="desc">
            <mat-form-field appearance="outline" [style.width.px]="300">
                <mat-label>Enlace 1</mat-label>
                <textarea class="form-text" matInput formControlName="link1"></textarea>
                <mat-error
                    *ngIf="formGroup.get('link1')?.errors && formGroup.get('link1')?.errors?.['required']">Descripción
                    obligatoria.</mat-error>
            </mat-form-field>
        </p>
        <p>
            <mat-form-field appearance="outline" [style.width.px]="300">
                <mat-label>Enlace 2</mat-label>

                <input type="text" matInput formControlName="link2" />
                <!-- <mat-icon matSuffix>title</mat-icon> -->
                <mat-error *ngIf="formGroup.get('link2')?.errors && formGroup.get('link2')?.errors?.['required']">Link
                    obligatorio.</mat-error>
            </mat-form-field>
        </p>

        <p><strong>PDF ACTUAL :</strong> {{ actualPdfName }}</p><br>

        <ngx-mat-file-input accept=".pdf" placeholder="Adjuntar pdf clickando en el icono"
            formControlName="pdf"></ngx-mat-file-input>

        <div class="button">
            <button class="send" type="submit" mat-button color="success">
                Añadir
            </button>
        </div>
        <div class="spinner" *ngIf="isSpinner">
            <mat-spinner [diameter]="30"></mat-spinner>
        </div>
        <div class="spinner" *ngIf="isSubmited">
            <svg viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
                <g stroke="currentColor" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="round"
                    stroke-linejoin="round">
                    <path class="circle"
                        d="M13 1C6.372583 1 1 6.372583 1 13s5.372583 12 12 12 12-5.372583 12-12S19.627417 1 13 1z" />
                    <path class="tick" d="M6.5 13.5L10 17 l8.808621-8.308621" />
                </g>
            </svg>
        </div>
    </form>
</div>