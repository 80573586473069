import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  ExternalLinks,
  GestionService,
} from 'src/app/_services/gestion.service';

@Component({
  selector: 'app-form-external-links',
  templateUrl: './form-external-links.component.html',
  styleUrls: ['./form-external-links.component.css'],
})
export class FormExternalLinksComponent implements OnInit {
  isSpinner: boolean = false;
  isLoaded: boolean = false;
  formGroup!: UntypedFormGroup;
  formValue: ExternalLinks[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private gestionService: GestionService
  ) {}

  async ngOnInit(): Promise<void> {
    (await this.gestionService.getExternalLinks()).subscribe((data) => {
      this.formValue = data;
      this.formGroup = this.formBuilder.group({
        link1: [this.formValue[0].link1, Validators.required],
        link2: [this.formValue[0].link2, Validators.required],
        link3: [this.formValue[0].link3, Validators.required],
      });
      this.isLoaded = true;
    });
  }

  async onSubmit(input: any): Promise<void> {
    if (this.formGroup.valid) {
      this.isSpinner = true;
      input.id = this.formValue[0].id;
      (await this.gestionService.setExternalLinks(input)).subscribe((data) => {
        this.formGroup = this.formBuilder.group({
          link1: [data.link1, Validators.required],
          link2: [data.link2, Validators.required],
          link3: [data.link3, Validators.required],
        });
        this.isSpinner = false;
      });
    }
  }
}
