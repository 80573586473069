<div class="background-color" *ngIf="isLoaded">
  <div class="content">
    <h1 class="title">Nuestros servicios</h1>
    <div fxLayout="row wrap">
      <div class="service-card" fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="50%">
        <div class="center-content">
          <img
            class="img"
            src="../../../assets/services/sheild-dynamic-gradient.png"
          />
          <h2><strong>{{ serviceInfo[0].service1}}</strong></h2>
        </div>
      </div>
      <div class="service-card" fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="50%">
        <div class="center-content">
          <img
            class="img"
            src="../../../assets/services/notebook-dynamic-color.png"
          />
          <h2><strong>{{ serviceInfo[0].service2}}</strong></h2>
        </div>
      </div>
      <div class="service-card" fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="50%">
        <div class="center-content">
          <img
            class="img"
            src="../../../assets/services/chat-text-dynamic-gradient.png"
          />
          <h2><strong>{{ serviceInfo[0].service3}}</strong></h2>
        </div>
      </div>

      <div class="service-card" fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="50%">
        <div class="center-content">
          <img
            class="img"
            src="../../../assets/services/bookmark-fav-dynamic-color.png"
          />
          <h2><strong>{{ serviceInfo[0].service4}}</strong></h2>
        </div>
      </div>
    </div>
  </div>
</div>

