<mat-card class="container-form mat-elevation-z0">
  <div *ngIf="!isAdd && !isEdit">
    <button (click)="openAdd()" class="add-button" mat-button>
      <mat-icon class="add-icon">add_circle_outline</mat-icon>
      <a class="add-text">Añadir votación</a>
    </button>
    <div class="dashboard" fxLayout="row wrap">
      <div class="example-container mat-elevation-z8">
        <mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              Título
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="Titulo">
              {{ row.title }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="option1">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              Opcion 1
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="Opcion 1">
              {{ row.option1 }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="option2">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              Opcion 2
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="Opcion 2">
              {{ row.option2 }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="result1">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              % Opción 1
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="% Opción 1">
              {{ row.result1 }}%
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="result2">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              % Opción 2
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="% Opción 2">
              {{ row.result2 }}%
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="show">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              Visible
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="Visible">
              {{ row.show ? "Si" : "No" }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <a class="edit-button" mat-button (click)="downloadPdf(element.id)">
                <mat-icon>picture_as_pdf</mat-icon>
              </a>
              <a class="edit-button" mat-button (click)="openEdit(element)">
                <mat-icon>edit</mat-icon>
              </a>
              <a class="remove-button" mat-button (click)="remove(element)">
                <mat-icon>delete</mat-icon>
              </a>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
        </mat-table>
      </div>
    </div>
  </div>
  <mat-paginator
    id="paginator"
    [pageSizeOptions]="[5, 10, 25, 100]"
  ></mat-paginator>

  <div *ngIf="isAdd">
    <button mat-button class="go-back-button" (click)="goBack()">
      <mat-icon class="add-icon">arrow_back_ios</mat-icon>
    </button>
    <a class="title"><strong>Añadir Votacion</strong></a>
    <div class="add-form">
      <form
        [formGroup]="formGroup"
        (ngSubmit)="onSubmit(formGroup.value)"
        class="form-input"
      >
        <p>
          <mat-form-field appearance="outline" [style.width.px]="300">
            <mat-label>Título</mat-label>

            <input type="text" matInput formControlName="title" required />
            <mat-error
              *ngIf="formGroup.get('title')?.errors && formGroup.get('title')?.errors?.['required']"
              >Título obligatorio.</mat-error
            >
          </mat-form-field>
        </p>
        <p>
          <mat-form-field appearance="outline" [style.width.px]="300">
            <mat-label>Opción 1</mat-label>

            <input type="text" matInput formControlName="option1" required />
            <mat-error
              *ngIf="formGroup.get('option1')?.errors && formGroup.get('option1')?.errors?.['required']"
              >Opcion 1 obligatoria.</mat-error
            >
          </mat-form-field>
        </p>
        <p>
          <mat-form-field appearance="outline" [style.width.px]="300">
            <mat-label>Link 1</mat-label>

            <input type="text" matInput formControlName="url1" required />
            <mat-error
              *ngIf="formGroup.get('url1')?.errors && formGroup.get('url1')?.errors?.['required']"
              >Link 1 obligatorio</mat-error
            >
          </mat-form-field>
        </p>
        <p>
          <mat-form-field appearance="outline" [style.width.px]="300">
            <mat-label>Opción 2</mat-label>

            <input type="text" matInput formControlName="option2" required />
            <mat-error
              *ngIf="formGroup.get('option2')?.errors && formGroup.get('option2')?.errors?.['required']"
              >Opcion 2 obligatoria.</mat-error
            >
          </mat-form-field>
        </p>
        <p>
          <mat-form-field appearance="outline" [style.width.px]="300">
            <mat-label>Link 2</mat-label>

            <input type="text" matInput formControlName="url2" required />
            <mat-error
              *ngIf="formGroup.get('url2')?.errors && formGroup.get('url2')?.errors?.['required']"
              >Link 2 obligatorio.</mat-error
            >
          </mat-form-field>
        </p>

        <p>
          <mat-checkbox
            class="first-check"
            color="primary"
            formControlName="show"
          >
            Visible
          </mat-checkbox>
        </p>

        <div class="button">
          <button class="send" type="submit" mat-button color="success">
            Añadir
          </button>
        </div>
        <div class="spinner" *ngIf="isSpinner">
          <mat-spinner [diameter]="30"></mat-spinner>
        </div>
        <div class="spinner" *ngIf="isSubmited">
          <svg viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
            <g
              stroke="currentColor"
              stroke-width="2"
              fill="none"
              fill-rule="evenodd"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path
                class="circle"
                d="M13 1C6.372583 1 1 6.372583 1 13s5.372583 12 12 12 12-5.372583 12-12S19.627417 1 13 1z"
              />
              <path class="tick" d="M6.5 13.5L10 17 l8.808621-8.308621" />
            </g>
          </svg>
        </div>
      </form>
    </div>
  </div>
  <div *ngIf="isEdit">
    <button mat-button class="go-back-button" (click)="goBack()">
      <mat-icon class="add-icon">arrow_back_ios</mat-icon>
    </button>
    <a class="title"><strong>Editar Votacion</strong></a>
    <div class="edit-form">
      <form
        [formGroup]="formGroup"
        (ngSubmit)="onSubmit(formGroup.value)"
        class="form-input"
      >
        <p>
          <mat-form-field appearance="outline" [style.width.px]="300">
            <mat-label>Título</mat-label>
            <input type="text" matInput formControlName="title" required />
            <mat-error
              *ngIf="formGroup.get('title')?.errors && formGroup.get('title')?.errors?.['required']"
              >Título obligatorio.</mat-error
            >
          </mat-form-field>
        </p>
        <p>
          <mat-form-field appearance="outline" [style.width.px]="300">
            <mat-label>Opción 1</mat-label>
            <input type="text" matInput formControlName="option1" required />
            <mat-error
              *ngIf="formGroup.get('option1')?.errors && formGroup.get('option1')?.errors?.['required']"
              >Opcion 1 obligatoria.</mat-error
            >
          </mat-form-field>
        </p>
        <p>
          <mat-form-field appearance="outline" [style.width.px]="300">
            <mat-label>Link 1</mat-label>
            <input type="text" matInput formControlName="url1" required />
            <mat-error
              *ngIf="formGroup.get('url1')?.errors && formGroup.get('url1')?.errors?.['required']"
              >Link 1 obligatorio.</mat-error
            >
          </mat-form-field>
        </p>
        <p>
          <mat-form-field appearance="outline" [style.width.px]="300">
            <mat-label>Opción 2</mat-label>
            <input type="text" matInput formControlName="option2" required />
            <mat-error
              *ngIf="formGroup.get('option2')?.errors && formGroup.get('option2')?.errors?.['required']"
              >Opcion 2 obligatoria.</mat-error
            >
          </mat-form-field>
        </p>
        <p>
          <mat-form-field appearance="outline" [style.width.px]="300">
            <mat-label>Link 2</mat-label>
            <input type="text" matInput formControlName="url2" required />
            <mat-error
              *ngIf="formGroup.get('url2')?.errors && formGroup.get('url2')?.errors?.['required']"
              >Link 2 obligatorio.</mat-error
            >
          </mat-form-field>
        </p>

        <p>
          <mat-checkbox
            class="first-check"
            color="primary"
            formControlName="show"
            [checked]="votationToEdit.show === 1 ? true : false"
          >
            Visible
          </mat-checkbox>
        </p>

        <div class="button">
          <button class="send" type="submit" mat-button color="success">
            Editar
          </button>
        </div>
        <div class="spinner" *ngIf="isSpinner">
          <mat-spinner [diameter]="30"></mat-spinner>
        </div>
        <div class="spinner" *ngIf="isSubmited">
          <svg viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
            <g
              stroke="currentColor"
              stroke-width="2"
              fill="none"
              fill-rule="evenodd"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path
                class="circle"
                d="M13 1C6.372583 1 1 6.372583 1 13s5.372583 12 12 12 12-5.372583 12-12S19.627417 1 13 1z"
              />
              <path class="tick" d="M6.5 13.5L10 17 l8.808621-8.308621" />
            </g>
          </svg>
        </div>
      </form>
    </div>
  </div>
</mat-card>
