import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { EmailService } from 'src/app/_services/email.service';
import { Contact, GestionService } from 'src/app/_services/gestion.service';
import Swal from 'sweetalert2/dist/sweetalert2.all.js';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
})
export class ContactComponent implements OnInit {
  formGroup!: UntypedFormGroup;
  contactInfo: Contact[] = [];
  isLoaded: boolean = false;
  email: string = '';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private gestionService: GestionService,
    private emailService: EmailService
  ) {}

  ngOnInit(): void {
    this.getContactInfo();
    this.formGroup = this.formBuilder.group({
      name: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      phone: [null],
      message: [null, Validators.required],
    });
  }

  async onSubmit(input: any): Promise<void> {
    if (this.formGroup.valid) {
      (await this.emailService.contactMail(input)).subscribe((data) => {
        if (data === 'OK') {
          Swal.fire({
            title: '¡Mensaje enviado correctamente!',
            text: 'Con la mayor brevedad posible te contactaremos al email indicado.',
            icon: 'success',
            confirmButtonText: 'OK',
            confirmButtonColor: '#011d35',
          }).then(() => {
            this.formGroup.reset();
          });
        } else {
          // Swal.fire({
          //   title: '¡Se ha producido un error!',
          //   text: 'Inténtelo de nuevo más tarde.',
          //   icon: 'error',
          //   confirmButtonText: 'OK',
          //   confirmButtonColor: '#011d35',
          // }).then(() => {
          //   this.formGroup.reset();
          // });
        }
      });
    }
  }

  async getContactInfo(): Promise<void> {
    (await this.gestionService.getContact()).subscribe((data) => {
      this.contactInfo = data;
      this.email = this.contactInfo[0].email;
      this.isLoaded = true;
    });
  }
}
