<div class="background-image" *ngIf="isLoaded">
  <h1 class="title">Preguntas frecuentes</h1>
  <div class="accordion">
    <mat-accordion multi>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <strong>{{faqs[0].q1}}</strong>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="my-color response">
          {{faqs[0].a1}}
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <strong>{{faqs[0].q2}}</strong>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="response">
          {{faqs[0].a2}}
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <strong>{{faqs[0].q3}}</strong>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="response">
          {{faqs[0].a3}}
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <strong>{{faqs[0].q4}}</strong>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="response">
          {{faqs[0].a4}}
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
