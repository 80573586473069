import { Component, OnInit } from '@angular/core';
import {
  Comunicate,
  ComunicateService,
} from 'src/app/_services/comunicate.service.ts';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css'],
})
export class NewsComponent implements OnInit {
  comunicates: Comunicate[] = [];
  isLoaded: boolean = false;

  constructor(private comunicateService: ComunicateService) {}

  async ngOnInit(): Promise<void> {
    (await this.comunicateService.getComunicatesImg()).subscribe(
      async (data) => {
        this.comunicates = this.transformImg(data);
        this.isLoaded = true;
      }
    );
  }

  private transformImg(dataArray: Comunicate[]): Comunicate[] {
    return dataArray.map((value) => {
      return {
        id: value.id,
        title: value.title,
        description: value.description,
        link: value.link,
        img: `data:image/png;base64,${value.img}`,
      };
    });
  }
}
