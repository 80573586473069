<div *ngIf="isLoaded">
    <div class="top-bar" fxLayout="row wrap">
        <div class="image" fxFlex="20%" fxFlex.md="100%" fxFlex.xs="100%" fxFlex.sm="100%">
            <div class="user">
                <strong><a>USUARIO</a></strong>
            </div>
            <p class="admin-text">
                <a>{{admin.name}}</a>
                <br><br>
                <a>{{admin.email}}</a>
            </p>
        </div>

        <div class="top-bar-second" fxFlex="80%" fxFlex.md="100%" fxFlex.xs="100%" fxFlex.sm="100%">
            <div class="route">
                <mat-icon class="home">home</mat-icon>
                <span>/</span>
                <span>Dashboard</span>
            </div>
            <div class="logout">
                <button mat-button (click)="goHome()"><mat-icon>logout</mat-icon></button>
            </div>
        </div>
    </div>
</div>