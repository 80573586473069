import { emitDistinctChangesOnlyDefaultValue } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-update-links',
  templateUrl: './update-links.component.html',
  styleUrls: ['./update-links.component.css'],
})
export class UpdateLinksComponent implements OnInit {
  main: boolean = true;
  nav: boolean = false;
  about: boolean = false;
  contact: boolean = false;
  faqs: boolean = false;
  benefits: boolean = false;
  links: boolean = false;
  privacy: boolean = false;
  consent: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  goBack(): void {
    this.main = true;
  }

  goTo(page: string): void {
    this.main = false;
    this.nav = false;
    this.about = false;
    this.contact = false;
    this.faqs = false;
    this.benefits = false;
    this.links = false;
    this.privacy = false;
    this.consent = false;
    switch (page) {
      case 'nav':
        this.nav = true;
        break;
      case 'about':
        this.about = true;
        break;
      case 'contact':
        this.contact = true;
        break;
      case 'faqs':
        this.faqs = true;
        break;
      case 'benefits':
        this.benefits = true;
        break;
      case 'links':
        this.links = true;
        break;
      case 'privacy':
        this.privacy = true;
        break;
      case 'consent':
        this.consent = true;
        break;
    }
  }

  edit(edit: string): void {
    switch (edit) {
      case 'nav':
        this.editNav();
        break;
      case 'about':
        this.editAbout();
        break;
      case 'contact':
        this.editContact();
        break;
      case 'faqs':
        this.editFaqs();
        break;
      case 'benefits':
        this.editBenefits();
        break;
      case 'links':
        this.editLinks();
        break;
    }
  }

  private editNav(): void {}

  private editAbout(): void {}

  private editContact(): void {}

  private editFaqs(): void {}

  private editBenefits(): void {}

  private editLinks(): void {}
}
