import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  goTop(): void {
    window.scrollTo({top:0, behavior: 'smooth'});
  }

  goToPrivacy(): void {
    this.router.navigate(['privacy-statement']);
  }

  goToConsent(): void {
    this.router.navigate(['member-consent']);
  }
}
