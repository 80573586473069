import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import {
  ResultVotation,
  Votation,
  VotationService,
} from 'src/app/_services/votation.service';

interface VotesPageInfo {
  id: number;
  title: string;
  option1: string;
  url1: string;
  url2: string;
  option2: string;
  result1: number;
  result2: number;
  show: number;
  display: boolean;
  optionSelected?: number;
  totalVotes: number;
}

@Component({
  selector: 'app-votes',
  templateUrl: './votes.component.html',
  styleUrls: ['./votes.component.css'],
})
export class VotesComponent implements OnInit {
  votesPageInfo: VotesPageInfo[] = [];
  votes: Votation[] = [];
  votesResults: ResultVotation[] = [];

  userId: string = '';

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private votationService: VotationService
  ) {
    this.userId = sessionStorage.getItem('userSession')!;
  }

  async ngOnInit(): Promise<void> {
    (await this.votationService.getVotation()).subscribe(async (data) => {
      this.votes = data;
      (await this.votationService.getResultsVotation(+this.userId)).subscribe(
        async (results) => {
          this.votesResults = results;
          this.votesPageInfo = this.setVotesToPage();
        }
      );
    });
  }

  setVotesToPage(): VotesPageInfo[] {
    let aux: VotesPageInfo[] = [];
    let cont: number = 0;
    for (let i = 0; i < this.votes.length; i++) {
      if (this.votes[i].show === 1) {
        if (this.votesResults.length > 0) {
          cont = 0;
          for (let j = 0; j < this.votesResults.length; j++) {
            if (this.votes[i].id === this.votesResults[j].idVotation) {
              const toInsert1 = {
                id: this.votes[i].id,
                title: this.votes[i].title,
                url1: this.votes[i].url1,
                url2: this.votes[i].url2,
                option1: this.votes[i].option1,
                option2: this.votes[i].option2,
                result1: this.votes[i].result1,
                result2: this.votes[i].result2,
                show: this.votes[i].show,
                display: true,
                optionSelected: this.votesResults[j].optionSelected,
                totalVotes: this.votes[i].totalVotes,
              };
              aux.push(toInsert1);
            } else {
              cont++;
              if (cont === this.votesResults.length) {
                const toInsert2 = {
                  id: this.votes[i].id,
                  title: this.votes[i].title,
                  option1: this.votes[i].option1,
                  option2: this.votes[i].option2,
                  url1: this.votes[i].url1,
                  url2: this.votes[i].url2,
                  result1: this.votes[i].result1,
                  result2: this.votes[i].result2,
                  show: this.votes[i].show,
                  display: false,
                  optionSelected: this.votesResults[j].optionSelected,
                  totalVotes: this.votes[i].totalVotes,
                };
                aux.push(toInsert2);
              }
            }
          }
        } else {
          const toInsert3 = {
            id: this.votes[i].id,
            title: this.votes[i].title,
            option1: this.votes[i].option1,
            option2: this.votes[i].option2,
            url1: this.votes[i].url1,
            url2: this.votes[i].url2,
            result1: this.votes[i].result1,
            result2: this.votes[i].result2,
            show: this.votes[i].show,
            display: false,
            optionSelected: 0,
            totalVotes: this.votes[i].totalVotes,
          };
          aux.push(toInsert3);
        }
      }
    }
    return aux;
  }

  async onSendButtonClick(vote: Votation): Promise<void> {
    const checked1 = (
      document.getElementById(`id1-${vote.id}`) as HTMLInputElement
    ).checked;
    const checked2 = (
      document.getElementById(`id2-${vote.id}`) as HTMLInputElement
    ).checked;
    if (checked1 || checked2) {
      this.setNewResults(vote, checked1);
    }
  }

  async setNewResults(vote: Votation, checked1: boolean): Promise<void> {
    const newResults = await this.generateNewResults(vote, checked1);

    await this.updateVotes(vote, newResults);
    await this.updateResultVotes(vote, checked1);

    this.votesPageInfo = this.votesPageInfo.map((el) => {
      return el.id === vote.id
        ? {
            id: el.id,
            title: el.title,
            option1: el.option1,
            option2: el.option2,
            url1: el.url1,
            url2: el.url2,
            result1: newResults[0],
            result2: newResults[1],
            show: el.show,
            display: true,
            optionSelected: checked1 === true ? 1 : 2,
            totalVotes: el.totalVotes + 1,
          }
        : el;
    });
    this.changeDetectorRef.detectChanges();
  }

  private async updateVotes(
    vote: Votation,
    newResults: Array<number>
  ): Promise<void> {
    (
      await this.votationService.updateVotation(
        {
          option1: vote.option1,
          option2: vote.option2,
          show: vote.show,
          title: vote.title,
          result1: newResults[0],
          result2: newResults[1],
          totalVotes: vote.totalVotes + 1,
        },
        vote.id
      )
    ).subscribe(async (data) => {});
  }

  private async updateResultVotes(
    vote: Votation,
    checked1: boolean
  ): Promise<void> {
    (
      await this.votationService.updateResultsVotation({
        idVotation: vote.id,
        idUser: +this.userId,
        optionSelected: checked1 ? 1 : 2,
      })
    ).subscribe(async (data) => {
      // console.log('response updateResultsVotation', data);
    });
  }

  private async generateNewResults(
    vote: Votation,
    checked: boolean
  ): Promise<[number, number]> {
    let cont = 0;
    let newResult1 = 0;
    let newResult2 = 0;
    if (checked) {
      cont = (vote.result1 * vote.totalVotes) / 100;
      cont++;
      newResult1 = (cont * 100) / (vote.totalVotes + 1);
      newResult2 = 100 - newResult1;
    } else {
      cont = (vote.result2 * vote.totalVotes) / 100;
      cont++;
      newResult2 = (cont * 100) / (vote.totalVotes + 1);
      newResult1 = 100 - newResult2;
    }
    return [Math.round(newResult1), Math.round(newResult2)];
  }
}
