import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-delete',
  templateUrl: './edit-delete.component.html',
  styleUrls: ['./edit-delete.component.css'],
})
export class EditDeleteComponent implements OnInit {
  isModal: boolean = false;

  constructor() {}

  ngOnInit(): void {}
  openModal(): void {
    this.isModal = true;
  }
}
