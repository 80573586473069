<mat-card class="container-form mat-elevation-z0">
  <div *ngIf="main">
    <h3>Gestión</h3>

    <div class="buttonMenu">
      <button class="buttons" mat-button (click)="goTo('nav')">TÍTULOS NAVEGACIÓN</button>
      <button class="buttons" mat-button (click)="goTo('about')">NOSOTROS Y SERVICIOS</button>
      <button class="buttons" mat-button (click)="goTo('contact')">CONTACTO</button>
      <button class="buttons" mat-button (click)="goTo('faqs')">FAQS</button>
      <button class="buttons" mat-button (click)="goTo('benefits')">BENEFICIOS</button>
      <button class="buttons" mat-button (click)="goTo('links')">LINKS EXTERNOS</button>

    </div>
    <div class="buttonMenu">
      <button class="buttons" mat-button (click)="goTo('privacy')">PRIVACIDAD</button>
      <button class="buttons" mat-button (click)="goTo('consent')">CONDICIONES</button>
    </div>
  </div>

  <div *ngIf="!main">
    <div class="title">
      <button class="super" mat-button class="go-back-button" (click)="goBack()">
        <mat-icon class="add-icon">arrow_back_ios</mat-icon>
      </button>
      <h3>Gestión</h3>
    </div>

    <div class="buttonMenu">
      <button class="buttons" mat-button (click)="goTo('nav')">TÍTULOS NAVEGACIÓN</button>
      <button class="buttons" mat-button (click)="goTo('about')">NOSOTROS Y SERVICIOS</button>
      <button class="buttons" mat-button (click)="goTo('contact')">CONTACTO</button>
      <button class="buttons" mat-button (click)="goTo('faqs')">FAQS</button>
      <button class="buttons" mat-button (click)="goTo('benefits')">BENEFICIOS</button>
      <button class="buttons" mat-button (click)="goTo('links')">LINKS EXTERNOS</button>
    </div>
    <div class="buttonMenu">
      <button class="buttons" mat-button (click)="goTo('privacy')">PRIVACIDAD</button>
      <button class="buttons" mat-button (click)="goTo('consent')">CONDICIONES</button>
    </div>
    <div *ngIf="nav" class="nav-card">
      <app-form-nav></app-form-nav>
    </div>
    <div *ngIf="about">
      <app-form-about></app-form-about>
    </div>
    <div *ngIf="contact">
      <app-form-contact></app-form-contact>
    </div>
    <div *ngIf="faqs">
      <app-form-faqs></app-form-faqs>
    </div>
    <div *ngIf="benefits">
      <app-form-benefits></app-form-benefits>
    </div>
    <div *ngIf="links">
      <app-form-external-links></app-form-external-links>
    </div>
    <div *ngIf="privacy">
      <app-form-privacy></app-form-privacy>
    </div>
    <div *ngIf="consent">
      <app-form-consent></app-form-consent>
    </div>
  </div>
</mat-card>