<mat-card class="container-form mat-elevation-z0">
  <div *ngIf="!isEdit && !isAdd">
    <button (click)="openAdd()" class="add-button" mat-button>
      <mat-icon class="add-icon">add_circle_outline</mat-icon>
      <a class="add-text">Añadir comunicado</a>
    </button>
    <div class="dashboard" fxLayout="row wrap">
      <div class="example-container mat-elevation-z8">
        <mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              Título
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="title">
              {{ row.title }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              Descripción
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="description">
              {{ row.description }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="link">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              Enlaces
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="name">
              {{ row.link }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
            </mat-header-cell>
            <mat-cell *matCellDef="let element" data-label="color">
              <a
                class="edit-button"
                mat-button
                (click)="openEdit(element)"
                ><mat-icon>edit</mat-icon></a
              >
              <a
                class="remove-button"
                mat-button
                (click)="remove(element)"
                ><mat-icon>delete</mat-icon></a
              >
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
        </mat-table>

      </div>
    </div>
  </div>
  <mat-paginator id="paginator" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

  <div *ngIf="isAdd">
    <button mat-button class="go-back-button" (click)="goBack()">
      <mat-icon class="add-icon">arrow_back_ios</mat-icon>
    </button>
    <a class="title"><strong>Añadir Comunicado</strong></a>
    <div class="add-form">
      <form
        [formGroup]="formGroup"
        (ngSubmit)="onSubmit(formGroup.value)"
        class="form-input"
        enctype="multipart/form-data"
      >
        <p>
          <mat-form-field appearance="outline" [style.width.px]="300">
            <mat-label>Título</mat-label>

            <input type="text" matInput formControlName="title" required />
            <!-- <mat-icon matSuffix>title</mat-icon> -->
            <mat-error
              *ngIf="formGroup.get('title')?.errors && formGroup.get('title')?.errors?.['required']"
              >Título obligatorio.</mat-error
            >
          </mat-form-field>
        </p>
        <p class="desc">
          <mat-form-field appearance="outline" [style.width.px]="300">
            <mat-label>Descripción</mat-label>
            <textarea
              class="form-text"
              matInput
              formControlName="description"
            ></textarea>
            <mat-error
              *ngIf="formGroup.get('description')?.errors && formGroup.get('description')?.errors?.['required']"
              >Descripción obligatoria.</mat-error
            >
          </mat-form-field>
        </p>
        <p>
          <mat-form-field appearance="outline" [style.width.px]="300">
            <mat-label>Enlace</mat-label>

            <input type="text" matInput formControlName="link" required />
            <!-- <mat-icon matSuffix>title</mat-icon> -->
            <mat-error
              *ngIf="formGroup.get('link')?.errors && formGroup.get('link')?.errors?.['required']"
              >Link obligatorio.</mat-error
            >
          </mat-form-field>
        </p>

        <ngx-mat-file-input accept=".png" placeholder="Adjuntar imagen clickando en el icono" formControlName="img"></ngx-mat-file-input>

        <div class="button">
          <button class="send" type="submit" mat-button color="success">
            Añadir
          </button>
        </div>
        <div class="spinner" *ngIf="isSpinner">
          <mat-spinner [diameter]="30"></mat-spinner>
        </div>
        <div class="spinner" *ngIf="isSubmited">
          <svg viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
            <g stroke="currentColor" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
              <path class="circle" d="M13 1C6.372583 1 1 6.372583 1 13s5.372583 12 12 12 12-5.372583 12-12S19.627417 1 13 1z"/>
              <path class="tick" d="M6.5 13.5L10 17 l8.808621-8.308621"/>
            </g>
          </svg>
        </div>
      </form>
    </div>
  </div>
  <div *ngIf="isEdit">
    <button mat-button class="go-back-button" (click)="goBack()">
      <mat-icon class="add-icon">arrow_back_ios</mat-icon>
    </button>
    <a class="title"><strong>Editar Comunicado</strong></a>

    <div class="edit-form">
      <form
        [formGroup]="formGroup"
        (ngSubmit)="onSubmit(formGroup.value)"
        class="form-input"
      >
        <p>
          <mat-form-field appearance="outline" [style.width.px]="300">
            <mat-label>Título</mat-label>

            <input type="text" matInput formControlName="title" required />
            <!-- <mat-icon matSuffix>title</mat-icon> -->
            <mat-error
              *ngIf="formGroup.get('title')?.errors && formGroup.get('title')?.errors?.['required']"
              >Título obligatorio.</mat-error
            >
          </mat-form-field>
        </p>
        <p class="desc">
          <mat-form-field appearance="outline" [style.width.px]="300">
            <mat-label>Descripción</mat-label>
            <textarea
              class="form-text"
              matInput
              formControlName="description"
            ></textarea>
            <mat-error
              *ngIf="formGroup.get('description')?.errors && formGroup.get('description')?.errors?.['required']"
              >Descripción obligatoria.</mat-error
            >
          </mat-form-field>
        </p>
        <p>
          <mat-form-field appearance="outline" [style.width.px]="300">
            <mat-label>Enlace</mat-label>

            <input type="text" matInput formControlName="link" required />
            <!-- <mat-icon matSuffix>title</mat-icon> -->
            <mat-error
              *ngIf="formGroup.get('link')?.errors && formGroup.get('link')?.errors?.['required']"
              >Link obligatorio.</mat-error
            >
          </mat-form-field>
        </p>

        <ngx-mat-file-input accept=".png" placeholder="Adjuntar imagen clickando en el icono" formControlName="img"></ngx-mat-file-input>

        <div class="button">
          <button class="send" type="submit" mat-button color="success">
            Editar
          </button>
        </div>
        <div class="spinner" *ngIf="isSpinner">
          <mat-spinner [diameter]="30"></mat-spinner>
        </div>
        <div class="spinner" *ngIf="isSubmited">
          <svg viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
            <g stroke="currentColor" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
              <path class="circle" d="M13 1C6.372583 1 1 6.372583 1 13s5.372583 12 12 12 12-5.372583 12-12S19.627417 1 13 1z"/>
              <path class="tick" d="M6.5 13.5L10 17 l8.808621-8.308621"/>
            </g>
          </svg>
        </div>
      </form>
    </div>
  </div>
</mat-card>
