import { Component, OnInit } from '@angular/core';
import {
  GestionService,
  PrivacyConsent,
} from 'src/app/_services/gestion.service';

@Component({
  selector: 'app-member-consent',
  templateUrl: './member-consent.component.html',
  styleUrls: ['./member-consent.component.css'],
})
export class MemberConsentComponent implements OnInit {
  isLoaded: boolean = false;
  consent: PrivacyConsent[] = [];

  constructor(private gestionService: GestionService) {}

  async ngOnInit(): Promise<void> {
    (await this.gestionService.getConsent()).subscribe((data) => {
      this.consent = data;
      this.isLoaded = true;
    });
  }
}
