<div class="containerB">
  <div class="container" fxLayout="row wrap" fxLayoutGap="grid">
    <div class="form" fxFlex="35%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="100%">
      <h1>¡Bienvenido!</h1>
      <br />
      <form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)" class="form-input">
        <p>
          <mat-form-field appearance="outline" [style.width.px]="300">
            <input type="text" matInput placeholder="Email" formControlName="email" required class="input" />
            <mat-icon matSuffix>email</mat-icon>
            <mat-error *ngIf="formGroup.get('email')?.errors && formGroup.get('email')?.errors?.['required']">Email
              obligatorio.</mat-error>
            <mat-error *ngIf="formGroup.get('email')?.errors && formGroup.get('email')?.errors?.['email']">Email format.
            </mat-error>
          </mat-form-field>
        </p>

        <p class="pass">
  <mat-form-field class="repeat-password" appearance="outline" [style.width.px]="300">
    <input
      [type]="hidePassword ? 'password' : 'text'"
      matInput
      placeholder="Password"
      formControlName="password"
      required
      class="input custom-password-field"
    />
    <button mat-icon-button matSuffix type="button" (click)="togglePasswordVisibility()">
      <mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
    </button>
    <mat-error *ngIf="formGroup.get('password')?.errors && formGroup.get('password')?.errors?.['required']">
      Contraseña obligatoria.
    </mat-error>
  </mat-form-field>
  <br />
  <a class="lost-pass" (click)="resetPassword()">¿Has olvidado la contraseña?</a>
  <br /><br />
  <a *ngIf="isLost" class="lost-pass-validate">Introduce un email válido</a>
</p>

        <div class="button">
          <button class="send" type="submit" mat-button color="success">
            Login
          </button>
        </div>
        <div class="spinner">
          <mat-spinner *ngIf="isSpinner" [diameter]="30"></mat-spinner>
        </div>
      </form>
      <br />

      <p class="register">
        <a class="registerA" (click)="goToRegister()">¿Aún no te has registrado? ¡Haz click aqui! </a>
        <br />
      </p>
    </div>

    <div class="img" fxFlex="65%" fxFlex.xs="65%" fxFlex.sm="65%" fxFlex.md="0%">
      <img src="../../../assets/member-image.jpg" alt="" />
    </div>
  </div>
</div>

<!-- <div class="form">
    <mat-card>
        <mat-card-title>INICIAR SESIÓN</mat-card-title>
        <mat-icon matSuffix>accessibility</mat-icon>

        <mat-card-content>
          <form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)" class="form-input">
              <p>
                <mat-form-field appearance="outline" [style.width.px]=300>
                    <input type="text" matInput placeholder="Email" formControlName="email" required>
                    <mat-icon matSuffix>email</mat-icon>
                    <mat-error *ngIf="formGroup.get('email')?.errors && formGroup.get('email')?.errors?.['required']">Email obligatorio.</mat-error>
                    <mat-error *ngIf="formGroup.get('email')?.errors && formGroup.get('email')?.errors?.['email']">Email format.</mat-error>
                </mat-form-field>
            </p>
      
            <p class="pass">
                <mat-form-field appearance="outline" [style.width.px]=300>
                    <input type="password" matInput placeholder="Password" formControlName="password" required>
                    <mat-icon matSuffix>password</mat-icon>
                    <mat-error *ngIf="formGroup.get('password')?.errors && formGroup.get('password')?.errors?.['required']">Contraseña obligatoria.</mat-error>
                </mat-form-field>
                <br>
                <a class="lost-pass">¿Has olvidado la contraseña?</a>
                <br>
            </p>

            <div class="button">
                <button class="send" type="submit" mat-button color="success">
                  Login
                </button>
            </div>
            <div class="spinner">
                <mat-spinner *ngIf="isSpinner" [diameter]="30"></mat-spinner>
            </div>

          </form>
        </mat-card-content>
      </mat-card>
</div> -->