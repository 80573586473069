import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-laws-agreements',
  templateUrl: './laws-agreements.component.html',
  styleUrls: ['./laws-agreements.component.css']
})
export class LawsAgreementsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
