import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-update-agreement',
  templateUrl: './update-agreement.component.html',
  styleUrls: ['./update-agreement.component.css'],
})
export class UpdateAgreementComponent implements OnInit {

  agreementSelected: number = 1;

  constructor() {}

  ngOnInit(): void {}

  goTo(agreementSelected: number) {
    this.agreementSelected = agreementSelected;
  }
}
