import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { GestionService, NavTitles } from 'src/app/_services/gestion.service';
import { environment } from 'src/environments/environment';

export interface MenuItem {
  label: string;
  showOnMobile: boolean;
  showOnTablet: boolean;
  showOnDesktop: boolean;
  route: string;
}

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.css'],
})
export class TopBarComponent implements OnInit {
  menu: boolean = false;
  isVisible: boolean = false;
  isLogged: boolean = false;
  isLoaded: boolean = false;
  isAdmin: boolean = false;
  screenWidth: number = 0;
  navTitles: NavTitles[] = [];

  constructor(
    private router: Router,
    private dtc: ChangeDetectorRef,
    private gestionService: GestionService
  ) {
    this.getScreenSize();
  }

  async ngOnInit(): Promise<void> {
    this.getNavInfo();
    const tokenAdmin = sessionStorage.getItem('adminSession');
    const tokenUser = sessionStorage.getItem('userSession');

    if (tokenAdmin != null && tokenAdmin != '') {
      this.isLogged = true;
      this.isAdmin = true;
      this.dtc.detectChanges();
    } else if (tokenUser != null && tokenUser != '') {
      this.isLogged = true;
      this.dtc.detectChanges();
    }
  }

  private async getNavInfo(): Promise<void> {
    (await this.gestionService.getNavTitles()).subscribe((data) => {
      this.navTitles = data;
      this.isLoaded = true;
    });
  }

  goHome(): void {
    this.router.navigate(['/home']);
  }

  goToPage(page: string): void {
    this.menu = !this.menu;

    switch (page) {
      case 'aboutUs':
        this.scrollViewTo('about');
        this.router.navigate(['/home/about']);
        break;
      case 'services':
        this.scrollViewTo('services');
        this.router.navigate(['/home/services']);
        break;
      case 'contact':
        this.scrollViewTo('contact');
        this.router.navigate(['/home/contact']);
        break;
      case 'privacy':
        this.router.navigate(['/privacy-statement']);
        break;
      case 'consent':
        this.router.navigate(['/member-consent']);
        break;
      case 'studentRegistration':
        this.router.navigate(['/students-registration']);
        break;
      case 'membershipRegistration':
        this.router.navigate(['/membership-registration']);
        break;
      case 'login':
        this.router.navigate(['/login']);
        break;
      case 'benefits':
        this.router.navigate(['/benefits']);
        break;
      case 'votes':
        this.router.navigate(['/votes']);
        break;
      case 'sector-agreement':
        this.router.navigate(['/sector-agreement']);
        break;
      case 'news':
        this.router.navigate(['/news']);
        break;
      case 'offers':
        this.router.navigate(['/offers']);
        break;
      case 'dashboard':
        this.router.navigate(['/dashboard']);
        break;
      default:
        break;
    }
  }

  goOut(): void {
    sessionStorage.removeItem('userSession');
    sessionStorage.removeItem('adminSession');

    window.location.href = environment.basePath;
  }

  openMenu(): void {
    this.menu = !this.menu;
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 1250) {
      this.isVisible = true;
    } else {
      this.isVisible = false;
    }
  }

  private scrollViewTo(to: string): void {
    setTimeout(() => {
      switch (to) {
        case 'about':
          document
            .getElementById('about')
            ?.scrollIntoView({ behavior: 'smooth' });
          break;
        case 'services':
          document
            .getElementById('services')
            ?.scrollIntoView({ behavior: 'smooth' });
          break;
        case 'contact':
          document
            .getElementById('contact')
            ?.scrollIntoView({ behavior: 'smooth' });
          break;
      }
    }, 400);
  }
}
