<div class="contact-form" *ngIf="isLoaded">
  <h1 class="title">Contáctanos</h1>
  <!-- <div class="contact-info">Telefono: +34 {{ contactInfo[0].phone }}</div> -->
  <div class="contact-info">Email: {{ email }}</div>
  <div class="fill">¡Rellene el formulario de contacto!</div>
  <form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)" class="form-input">
    <div fxLayout="row wrap">
      <div class="service-card-1" fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="100%">
        <div class="">
          <p>
            <mat-form-field appearance="outline">
              <input type="text" matInput placeholder="Nombre" formControlName="name" />
              <mat-icon matSuffix>person_pin</mat-icon>
              <mat-error *ngIf="formGroup.get('name')?.errors && formGroup.get('name')?.errors?.['required']">Nombre
                obligatorio.</mat-error>
            </mat-form-field>
          </p>
          <p>
            <mat-form-field appearance="outline">
              <input type="text" matInput placeholder="Email" formControlName="email" />
              <mat-icon matSuffix>email</mat-icon>
              <mat-error *ngIf="formGroup.get('email')?.errors && formGroup.get('email')?.errors?.['required']">Email
                obligatorio.</mat-error>
              <mat-error *ngIf="formGroup.get('email')?.errors && formGroup.get('email')?.errors?.['email']">Email
                format.</mat-error>
            </mat-form-field>
          </p>
          <p>
            <mat-form-field appearance="outline">
              <input type="text" matInput placeholder="Teléfono de contacto" formControlName="phone" />
              <mat-icon matSuffix>phone</mat-icon>
            </mat-form-field>
          </p>
        </div>
      </div>
      <div class="service-card-2" fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="100%">
        <div class="center-content">
          <p>
            <mat-form-field class="textarea-width" appearance="outline">
              <textarea class="form-text" matInput placeholder="Mensaje" formControlName="message"></textarea>
              <mat-error
                *ngIf="formGroup.get('message')?.errors && formGroup.get('message')?.errors?.['required']">Mensaje
                obligatorio.</mat-error>
            </mat-form-field>
          </p>
        </div>
      </div>
    </div>
    <div class="button">
      <button class="send" type="submit" mat-button color="success">
        Enviar
      </button>
    </div>
  </form>
</div>