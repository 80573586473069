<div class="add-form" *ngIf="isLoaded">
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)" >
        <div class="form-input">
            <p>
                <mat-form-field appearance="outline">
                <mat-label> Pregunta 1</mat-label>

                    <input type="text" matInput formControlName="q1" required>
                    <!-- <mat-icon matSuffix>q1</mat-icon> -->
                    <mat-error *ngIf="formGroup.get('q1')?.errors && formGroup.get('q1')?.errors?.['required']">Título obligatorio.</mat-error>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="outline">
                <mat-label> Respuesta 1</mat-label>

                    <input type="text" matInput formControlName="a1" required>
                    <!-- <mat-icon matSuffix>a1</mat-icon> -->
                    <mat-error *ngIf="formGroup.get('a1')?.errors && formGroup.get('a1')?.errors?.['required']">Opcion 1 obligatoria.</mat-error>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="outline">
                <mat-label> Pregunta 2</mat-label>

                    <input type="text" matInput formControlName="q2" required>
                    <!-- <mat-icon matSuffix>q2</mat-icon> -->
                    <mat-error *ngIf="formGroup.get('q2')?.errors && formGroup.get('q2')?.errors?.['required']">Opcion 2 obligatoria.</mat-error>
                </mat-form-field>
            </p>

            <p>
                <mat-form-field appearance="outline">
                <mat-label> Respuesta 2</mat-label>

                    <input type="text" matInput formControlName="a2" required>
                    <!-- <mat-icon matSuffix>a2</mat-icon> -->
                    <mat-error *ngIf="formGroup.get('a2')?.errors && formGroup.get('a2')?.errors?.['required']">Opcion 2 obligatoria.</mat-error>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="outline">
                <mat-label> Pregunta 3</mat-label>

                    <input type="text" matInput formControlName="q3" required>
                    <!-- <mat-icon matSuffix>q3</mat-icon> -->
                    <mat-error *ngIf="formGroup.get('q3')?.errors && formGroup.get('q3')?.errors?.['required']">Título obligatorio.</mat-error>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="outline">
                <mat-label> Respuesta 3</mat-label>

                    <input type="text" matInput formControlName="a3" required>
                    <!-- <mat-icon matSuffix>a3</mat-icon> -->
                    <mat-error *ngIf="formGroup.get('a3')?.errors && formGroup.get('a3')?.errors?.['required']">Opcion 1 obligatoria.</mat-error>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="outline">
                <mat-label> Pregunta 4</mat-label>

                    <input type="text" matInput formControlName="q4" required>
                    <!-- <mat-icon matSuffix>q4</mat-icon> -->
                    <mat-error *ngIf="formGroup.get('q4')?.errors && formGroup.get('q4')?.errors?.['required']">Opcion 2 obligatoria.</mat-error>
                </mat-form-field>
            </p>

            <p>
                <mat-form-field appearance="outline">
                <mat-label> Respuesta 4</mat-label>

                    <input type="text" matInput formControlName="a4" required>
                    <!-- <mat-icon matSuffix>a4</mat-icon> -->
                    <mat-error *ngIf="formGroup.get('a4')?.errors && formGroup.get('a4')?.errors?.['required']">Opcion 2 obligatoria.</mat-error>
                </mat-form-field>
            </p>
           

 
            
            
            
        </div>
        <div class="action">
            <div class="button">
                <button class="send" type="submit" mat-button color="success">
                    Actualizar
                </button>
            </div>
            <div class="spinner">
                <mat-spinner *ngIf="isSpinner" [diameter]="30"></mat-spinner>
            </div>
        </div>
        
    </form>
</div>