<div class="add-form" *ngIf="isLoaded">
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)" >
        <div class="form-input">
            <p>
                <mat-form-field appearance="outline" [style.width.px]=200>
                <mat-label>{{ formValue[0].title1 }}</mat-label>

                    <input type="text" matInput formControlName="title1" required>
                    <!-- <mat-icon matSuffix>title1</mat-icon> -->
                    <mat-error *ngIf="formGroup.get('title1')?.errors && formGroup.get('title1')?.errors?.['required']">Título obligatorio.</mat-error>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="outline" [style.width.px]=200>
                <mat-label>{{ formValue[0].title2 }}</mat-label>

                    <input type="text" matInput formControlName="title2" required>
                    <!-- <mat-icon matSuffix>title2</mat-icon> -->
                    <mat-error *ngIf="formGroup.get('title2')?.errors && formGroup.get('title2')?.errors?.['required']">Opcion 1 obligatoria.</mat-error>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="outline" [style.width.px]=200>
                <mat-label>{{ formValue[0].title3 }}</mat-label>

                    <input type="text" matInput formControlName="title3" required>
                    <!-- <mat-icon matSuffix>title3</mat-icon> -->
                    <mat-error *ngIf="formGroup.get('title3')?.errors && formGroup.get('title3')?.errors?.['required']">Opcion 2 obligatoria.</mat-error>
                </mat-form-field>
            </p>

            <p>
                <mat-form-field appearance="outline" [style.width.px]=200>
                <mat-label>{{ formValue[0].title4 }}</mat-label>

                    <input type="text" matInput formControlName="title4" required>
                    <!-- <mat-icon matSuffix>title4</mat-icon> -->
                    <mat-error *ngIf="formGroup.get('title4')?.errors && formGroup.get('title4')?.errors?.['required']">Opcion 2 obligatoria.</mat-error>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="outline" [style.width.px]=200>
                <mat-label>{{ formValue[0].title5 }}</mat-label>

                    <input type="text" matInput formControlName="title5" required>
                    <!-- <mat-icon matSuffix>title5</mat-icon> -->
                    <mat-error *ngIf="formGroup.get('title5')?.errors && formGroup.get('title5')?.errors?.['required']">Título obligatorio.</mat-error>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="outline" [style.width.px]=200>
                <mat-label>{{ formValue[0].title6 }}</mat-label>

                    <input type="text" matInput formControlName="title6" required>
                    <!-- <mat-icon matSuffix>title6</mat-icon> -->
                    <mat-error *ngIf="formGroup.get('title6')?.errors && formGroup.get('title6')?.errors?.['required']">Opcion 1 obligatoria.</mat-error>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="outline" [style.width.px]=200>
                <mat-label>{{ formValue[0].title7 }}</mat-label>

                    <input type="text" matInput formControlName="title7" required>
                    <!-- <mat-icon matSuffix>title7</mat-icon> -->
                    <mat-error *ngIf="formGroup.get('title7')?.errors && formGroup.get('title7')?.errors?.['required']">Opcion 2 obligatoria.</mat-error>
                </mat-form-field>
            </p>

            <p>
                <mat-form-field appearance="outline" [style.width.px]=200>
                <mat-label>{{ formValue[0].title8 }}</mat-label>

                    <input type="text" matInput formControlName="title8" required>
                    <!-- <mat-icon matSuffix>title8</mat-icon> -->
                    <mat-error *ngIf="formGroup.get('title8')?.errors && formGroup.get('title8')?.errors?.['required']">Opcion 2 obligatoria.</mat-error>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="outline" [style.width.px]=200>
                <mat-label>{{ formValue[0].title9 }}</mat-label>

                    <input type="text" matInput formControlName="title9" required>
                    <!-- <mat-icon matSuffix>title9</mat-icon> -->
                    <mat-error *ngIf="formGroup.get('title9')?.errors && formGroup.get('title9')?.errors?.['required']">Título obligatorio.</mat-error>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="outline" [style.width.px]=200>
                <mat-label>{{ formValue[0].title10 }}</mat-label>

                    <input type="text" matInput formControlName="title10" required>
                    <!-- <mat-icon matSuffix>title10</mat-icon> -->
                    <mat-error *ngIf="formGroup.get('title10')?.errors && formGroup.get('title10')?.errors?.['required']">Opcion 1 obligatoria.</mat-error>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="outline" [style.width.px]=200>
                <mat-label>{{ formValue[0].title11 }}</mat-label>

                    <input type="text" matInput formControlName="title11" required>
                    <!-- <mat-icon matSuffix>title11</mat-icon> -->
                    <mat-error *ngIf="formGroup.get('title11')?.errors && formGroup.get('title11')?.errors?.['required']">Opcion 2 obligatoria.</mat-error>
                </mat-form-field>
            </p>

 
            
            
            
        </div>
        <div class="action">
            <div class="button">
                <button class="send" type="submit" mat-button color="success">
                    Actualizar
                </button>
            </div>
            <div class="spinner">
                <mat-spinner *ngIf="isSpinner" [diameter]="30"></mat-spinner>
            </div>
        </div>
        
    </form>
</div>