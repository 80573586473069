import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Comunicate } from 'src/app/_services/comunicate.service.ts';
import { ComunicateService } from 'src/app/_services/comunicate.service.ts';

interface FormInput {
  title: string;
  description: string;
  link: string;
}

interface Link {
  url: string;
}

@Component({
  selector: 'app-add-com',
  templateUrl: './add-com.component.html',
  styleUrls: ['./add-com.component.css'],
})
export class AddComComponent {
  fileName: string = '';
  isSubmited: boolean = false;
  isAdd: boolean = false;
  isEdit: boolean = false;
  isSpinner: boolean = false;

  displayedColumns = ['title', 'description', 'link', 'action'];
  dataSource!: MatTableDataSource<Comunicate>;
  formGroup!: UntypedFormGroup;
  link: Link[] = [];
  file!: File;

  comunicates: Comunicate[] = [];
  comunicateToEdit!: Comunicate;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private comunicateService: ComunicateService
  ) {}

  async ngOnInit(): Promise<void> {
    this.isSubmited = false;

    (await this.comunicateService.getComunicates()).subscribe((data) => {
      this.comunicates = data;
      this.dataSource = new MatTableDataSource(this.comunicates);

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });

    this.formGroup = this.formBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      link: [''],
      img: [null],
    });
  }

  ngAfterViewInit() {}

  openAdd(): void {
    document.getElementById('paginator')!.style.display = 'none';
    this.formGroup = this.formBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      link: [''],
      img: [null],
    });
    this.isSubmited = false;
    this.isAdd = true;
  }

  openEdit(element: any): void {
    document.getElementById('paginator')!.style.display = 'none';
    this.comunicateToEdit = element;

    this.formGroup = this.formBuilder.group({
      title: [element.title, Validators.required],
      description: [element.description, Validators.required],
      link: [element.link],
      img: [null],
    });
    this.isEdit = true;
  }

  async remove(element: any): Promise<void> {
    await this.comunicateService.deleteComunicate(element.id);
    this.comunicates = this.comunicates.filter(({ id }) => id !== element.id);
    this.dataSource = new MatTableDataSource(this.comunicates);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  goBack(): void {
    document.getElementById('paginator')!.style.display = 'block';
    this.isSubmited = false;
    this.isAdd = false;
    this.isEdit = false;
    this.formGroup = this.formBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      link: [''],
      cv: [null],
    });
  }

  addComunicate(input: Comunicate): void {
    this.comunicates.push(input);
    this.dataSource = new MatTableDataSource(this.comunicates);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.goBack();
  }

  editComunicate(input: Comunicate): void {
    this.comunicates = this.comunicates.map((data) => {
      return data.id === this.comunicateToEdit.id
        ? {
            id: this.comunicateToEdit.id,
            title: input.title,
            description: input.description,
            link: input.link,
            img: input.img,
          }
        : data;
    });
    this.dataSource = new MatTableDataSource(this.comunicates);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.goBack();
  }

  async onSubmit(input: any): Promise<void> {
    if (this.formGroup.valid) {
      this.isSpinner = true;
      if (this.isAdd) {
        //llamada al back
        (await this.comunicateService.setComunicate(input)).subscribe(
          (data) => {
            this.isSpinner = false;
            this.isSubmited = true;
            setTimeout(() => {
              this.isSubmited = false;
              this.addComunicate(data);
            }, 2000);
          }
        );
      }
      if (this.isEdit) {
        //llamada al back
        (
          await this.comunicateService.editComunicate(
            input,
            this.comunicateToEdit.id
          )
        ).subscribe((data) => {
          this.isSpinner = false;
          this.isSubmited = true;
          setTimeout(() => {
            this.isSubmited = false;
            this.editComunicate(data);
          }, 2000);
        });
      }
    }
  }
}
