import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminData, AdminService } from 'src/app/_services/admin.service';

@Component({
  selector: 'app-top-bar-dashboard',
  templateUrl: './top-bar-dashboard.component.html',
  styleUrls: ['./top-bar-dashboard.component.css'],
})
export class TopBarDashboardComponent implements OnInit {
  admin!: AdminData;
  adminId: string = '';
  isLoaded: boolean = false;

  constructor(private router: Router, private adminService: AdminService) {}

  async ngOnInit(): Promise<void> {
    const adminSession = sessionStorage.getItem('adminSession');
    if (adminSession)
    (await this.adminService.getAdminById(+adminSession)).subscribe(async (data) => {
      this.admin = data;
      this.isLoaded = true;
    });
  }

  goHome(): void {
    this.router.navigate(['/']);
  }
}
