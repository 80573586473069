import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-com',
  templateUrl: './edit-com.component.html',
  styleUrls: ['./edit-com.component.css']
})
export class EditComComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
