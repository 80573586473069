import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Faqs, GestionService } from 'src/app/_services/gestion.service';

@Component({
  selector: 'app-form-faqs',
  templateUrl: './form-faqs.component.html',
  styleUrls: ['./form-faqs.component.css'],
})
export class FormFaqsComponent implements OnInit {
  isSpinner: boolean = false;
  isLoaded: boolean = false;
  formGroup!: UntypedFormGroup;
  formValue: Faqs[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private gestionService: GestionService
  ) {}

  async ngOnInit(): Promise<void> {
    (await this.gestionService.getFaqs()).subscribe((data) => {
      this.formValue = data;
      this.formGroup = this.formBuilder.group({
        q1: [this.formValue[0].q1, Validators.required],
        a1: [this.formValue[0].a1, Validators.required],
        q2: [this.formValue[0].q2, Validators.required],
        a2: [this.formValue[0].a2, Validators.required],
        q3: [this.formValue[0].q3, Validators.required],
        a3: [this.formValue[0].a3, Validators.required],
        q4: [this.formValue[0].q4, Validators.required],
        a4: [this.formValue[0].a4, Validators.required],
      });
      this.isLoaded = true;
    });
  }

  async onSubmit(input: Faqs): Promise<void> {
    if (this.formGroup.valid) {
      this.isSpinner = true;
      input.id = this.formValue[0].id;
      (await this.gestionService.setFaqs(input)).subscribe((data) => {
        this.formGroup = this.formBuilder.group({
          q1: [data.q1, Validators.required],
          a1: [data.a1, Validators.required],
          q2: [data.q2, Validators.required],
          a2: [data.a2, Validators.required],
          q3: [data.q3, Validators.required],
          a3: [data.a3, Validators.required],
          q4: [data.q4, Validators.required],
          a4: [data.a4, Validators.required],
        });
        this.isSpinner = false;
      });
    }
  }
}
