<div class="cristal"></div>
<div class="modal">
  <h1>Editando algo</h1>
  <hr />
  <br />
  <br />

  <input type="text" name="" id="" /> <br /><br />
  <input type="text" name="" id="" /> <br /><br />
  <input type="text" name="" id="" /> <br /><br />
  <button mat-button class="save">Guardar</button> &nbsp;
  <button class="close" (click)="closeModal()" mat-button>
    Cerrar ventana
  </button>
</div>
