import { Component, OnInit } from '@angular/core';
import { About, GestionService } from 'src/app/_services/gestion.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css'],
})
export class ServicesComponent implements OnInit {
  serviceInfo: About[] = [];
  isLoaded: boolean = false;

  constructor(private gestionService: GestionService) {}

  async ngOnInit(): Promise<void> {
    (await this.gestionService.getAbout()).subscribe((data) => {
      this.serviceInfo = data;
      this.isLoaded = true;
    });
  }
}
