import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  Agreement,
  AgreementService,
} from 'src/app/_services/agreement.service';

@Component({
  selector: 'app-form-agreement4',
  templateUrl: './form-agreement4.component.html',
  styleUrls: ['./form-agreement4.component.css'],
})
export class FormAgreement4Component implements OnInit {
  actualPdfName: string = '';
  isSpinner: boolean = false;
  isLoaded: boolean = false;
  isSubmited: boolean = false;
  agreement!: Agreement;
  formGroup!: UntypedFormGroup;

  constructor(
    private agreementService: AgreementService,
    private formBuilder: UntypedFormBuilder
  ) {}

  async ngOnInit(): Promise<void> {
    this.isSubmited = false;

    (await this.agreementService.getAgreements(4)).subscribe((data) => {
      this.agreement = data;
      this.actualPdfName = data.pdf !== null ? data.pdf : 'Por defecto';
      this.formGroup = this.formBuilder.group({
        title: [this.agreement.title, Validators.required],
        link1: [this.agreement?.link1 || ''],
        link2: [this.agreement?.link2 || ''],
        pdf: [null],
      });
      this.isLoaded = true;
    });
  }

  async updateLocalAgreement(newAgreement: Agreement): Promise<void> {
    this.formGroup = this.formBuilder.group({
      title: [newAgreement.title, Validators.required],
      link1: [newAgreement.link1],
      link2: [newAgreement.link2],
      pdf: [null],
    });
  }

  async onSubmit(input: any): Promise<void> {
    if (this.formGroup.valid) {
      this.isSpinner = true;
      //llamada al back
      (
        await this.agreementService.setAgreement(4, {
          ...input,
          id: this.agreement.id,
        })
      ).subscribe((data) => {
        this.isSpinner = false;
        this.isSubmited = true;
        setTimeout(() => {
          this.isSubmited = false;
          this.actualPdfName = input.pdf.name;
          this.updateLocalAgreement(data);
        }, 2000);
      });
    }
  }
}
