<div class="dialog">
    <h1 mat-dialog-title>Nueva contraseña</h1>
    <input class="pass" id="pass" type="password" placeholder="Nueva contraseña"><br>
    <input class="pass" id="repeat-pass" type="password" placeholder="Repetir contraseña">
    <div class="errors">
        <a id="is-null">Debe introducir las contraseñas</a>
        <a id="is-bigger">Las contraseñas deben tener mínimo 8 caracteres</a>
        <a id="is-different">Las contraseñas no coinciden</a>
    </div>
</div>
<div class="dialog-buttons">
    <button class="send" mat-button (click)="onSubmitChangePassword()">Cambiar</button>
    <button class="cancel" mat-button (click)="onNoClick()">Cancelar</button>
</div>