import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserModel } from '../_models/user.model';

export interface AdminData {
  id: number;
  name: string;
  email: string;
  password: string;
  role: number;
}

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(private http: HttpClient) {}

  async getAdmins(): Promise<Observable<AdminData[]>> {
    const headers: Record<string,string> = {
      'Authorization': sessionStorage.getItem('token')!
    }
    const path = environment.apiBasePath + 'admin/getAdmins';
    return this.http.get<AdminData[]>(path, {headers});
  }

  async loginAdmin(input: any): Promise<Observable<any>> {
    const path = environment.apiBasePath + 'admin/loginAdmin';
    return this.http.post<any>(path, input);
  }

  async getAdminById(id: number): Promise<Observable<AdminData>> {
    const headers: Record<string,string> = {
      'Authorization': sessionStorage.getItem('token')!
    }
    const path = `${environment.apiBasePath}admin/getAdmin/${id}`;
    return this.http.get<AdminData>(path, {headers});
  }
}
