<div fxLayout="row wrap" fxLayoutGap="grid" class="first">
  <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
    <h1>Student Registration</h1>
    <p>
      <input type="text" /> <br />
      <br />
      <input type="text" /> <br />
      <br />
      <input type="text" /> <br />
      <br />
      <input type="text" />
    </p>
  </div>
</div>
