<div class="container" fxLayout="row wrap" fxLayoutGap="grid">
  <div class="a" fxFlex="30%" fxFlex.xs="100%" fxFlex.sm="30%">
    <img src="../../../assets/logo-definitivo.jpeg" alt="" />
  </div>

  <div fxFlex="40%" fxFlex.xs="100%" fxFlex.sm="40%">
    <!--<p>&copy; 2022 All Rights Reserved.</p>-->
    <p>
      <a (click)="goToPrivacy()"> Política de privacidad</a> |
      <a (click)="goToConsent()">Términos y condiciones</a>
    </p>
  </div>

  <div fxFlex="30%" fxFlex.xs="100%" fxFlex.sm="30%" class="icons">
    <a target="_blank" href="https://es-es.facebook.com/">
      <img class="social" src="../../../assets/footer/facebook.png" alt="" />
    </a>

    <a target="_blank" href="https://twitter.com/">
      <img class="social" src="../../../assets/footer/gorjeo.png" alt="" />
    </a>
    &nbsp;&nbsp;
    <a (click)="goTop()">
      <mat-icon class="social">arrow_upward</mat-icon>
    </a>
    &nbsp;&nbsp;
  </div>
</div>