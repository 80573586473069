<div class="add-form" *ngIf="isLoaded">
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)" >
        <div class="form-input">
            <p>
                <mat-form-field appearance="outline">
                <mat-label>Teléfono de contacto</mat-label>

                    <input type="number" matInput formControlName="phone" required>
                    <!-- <mat-icon matSuffix>phone</mat-icon> -->
                    <mat-error *ngIf="formGroup.get('phone')?.errors && formGroup.get('phone')?.errors?.['required']">Teléfono obligatorio.</mat-error>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="outline">
                <mat-label>Email de contacto</mat-label>

                    <input type="email" matInput formControlName="email" required>
                    <!-- <mat-icon matSuffix>email</mat-icon> -->
                    <mat-error *ngIf="formGroup.get('email')?.errors && formGroup.get('email')?.errors?.['required']">Email obligatorio.</mat-error>
                    <mat-error
                    *ngIf="formGroup.get('email')?.errors && formGroup.get('email')?.errors?.['email']"
                    >Email format.</mat-error
                  >
                </mat-form-field>
            </p>

        </div>
        <div class="action">
            <div class="button">
                <button class="send" type="submit" mat-button color="success">
                    Actualizar
                </button>
            </div>
            <div class="spinner">
                <mat-spinner *ngIf="isSpinner" [diameter]="30"></mat-spinner>
            </div>
        </div>
        
    </form>
</div>