<div *ngIf="isLoaded">
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">
        <p>
            <mat-form-field class="paragraph-size" appearance="outline">
                <mat-label>Párrafo 1</mat-label>
                <textarea type="text" matInput formControlName="paragraph1" class="textarea-width" style="height: 500px;"></textarea>
                <!-- <mat-icon matSuffix>paragraph1</mat-icon> -->
                <mat-error
                    *ngIf="formGroup.get('paragraph1')?.errors && formGroup.get('paragraph1')?.errors?.['required']">Párrafo
                    obligatorio.</mat-error>
            </mat-form-field>
        </p>


        <div class="action">
            <div class="button">
                <button class="send" type="submit" mat-button color="success">
                    Actualizar
                </button>
            </div>
            <div class="spinner">
                <mat-spinner *ngIf="isSpinner" [diameter]="30"></mat-spinner>
            </div>
            <div class="spinner" *ngIf="isSubmited">
                <svg viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
                  <g stroke="currentColor" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                    <path class="circle" d="M13 1C6.372583 1 1 6.372583 1 13s5.372583 12 12 12 12-5.372583 12-12S19.627417 1 13 1z"/>
                    <path class="tick" d="M6.5 13.5L10 17 l8.808621-8.308621"/>
                  </g>
                </svg>
              </div>
        </div>
    </form>
</div>