import { Component, OnInit } from '@angular/core';
import { Benefits, GestionService } from 'src/app/_services/gestion.service';

@Component({
  selector: 'app-member-benefits',
  templateUrl: './member-benefits.component.html',
  styleUrls: ['./member-benefits.component.css'],
})
export class MemberBenefitsComponent implements OnInit {
  isLoaded: boolean = false;
  benefits: Benefits[] = [];

  constructor(private gestionService: GestionService) {}

  async ngOnInit(): Promise<void> {
    console.log('ngOnInit MemberBenefitsComponent');
    (await this.gestionService.getBenefits()).subscribe((data) => {
      this.benefits = data;
      this.isLoaded = true;
      console.log('memberBenefitsComponent data, ', data);
    });
  }
}
