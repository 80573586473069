<div class="first">
  <div>
    <h1>Votaciones de los socios</h1>
    <hr />
  </div>
  <div class="content">
    <mat-card
      class="cards mat-elevation-z7"
      *ngFor="let vote of votesPageInfo"
      [ngStyle]="{ display: vote.show ? 'block' : 'none' }"
    >
      <div *ngIf="!vote.display">
        <h2 class="voteTitle">{{ vote.title }}</h2>
        <input type="radio" name="radio" [id]="'id1-' + vote.id" /><strong
          >{{ vote.option1 }}</strong
        ><br />
        <br />
        &nbsp;&nbsp;
        <a class="links" href="{{ vote.url1 }}" [id]="'id1-' + vote.id"
          >Mas información.</a
        ><br />
        <input
          type="radio"
          class="second-radio"
          name="radio"
          [id]="'id2-' + vote.id"
        /><strong>{{ vote.option2 }}</strong
        ><br /><br />
        &nbsp;&nbsp;<a
          class="links"
          href="{{ vote.url1 }}"
          [id]="'id1-' + vote.id"
          >Mas información.</a
        ><br />
        <div class="send-section">
          <div class="total-votes">
            <a
              >Votos totales: <strong>{{ vote.totalVotes }}</strong></a
            >
          </div>
          <div class="button">
            <button
              class="send"
              type="submit"
              mat-button
              color="success"
              (click)="onSendButtonClick(vote)"
            >
              Enviar voto
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="vote.display" class="no-display">
        <h2 class="voteTitle">{{ vote.title }}</h2>
        <a class="percentage"
          ><strong>{{ vote.result1 }}%</strong></a
        >
        <mat-icon *ngIf="vote.optionSelected === 1"
          >check_circle_outline</mat-icon
        >
        <a class="break"
          ><strong>{{ vote.option1 }} </strong></a
        >
        <br />
        <div class="result1" [ngStyle]="{ width: vote.result1 + '%' }"></div>

        <a class="percentage"
          ><strong>{{ vote.result2 }}%</strong></a
        >
        <mat-icon *ngIf="vote.optionSelected === 2"
          >check_circle_outline</mat-icon
        >
        <strong>{{ vote.option2 }}</strong>
        <br />
        <div class="result2" [ngStyle]="{ width: vote.result2 + '%' }"></div>
        <br />
        <div class="total-votes">
          <a
            >Votos totales: <strong>{{ vote.totalVotes }}</strong> | Ya has
            participado en esta votación, ¡gracias!</a
          >
        </div>
      </div>
    </mat-card>
  </div>
</div>
