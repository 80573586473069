<div fxLayout="row wrap" fxLayoutGap="grid" class="first">
  <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
    <h1>Laws Agreements</h1>
    <p>
      Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nulla repellat
      rerum accusamus quas voluptatem, ducimus reprehenderit id magni amet
      voluptates<br />
      eligendi consequuntur corporis sapiente reiciendis perspiciatis
      perferendis. Tempore, nostrum qui. Lorem ipsum dolor sit amet consectetur
      adipisicing elit. Velit atque quos ipsa enim neque unde reprehenderit fuga
      at voluptatem est. Quam quia ab molestiae obcaecati, magni soluta quis nam
      alias! Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero,
      autem assumenda. Laborum at praesentium maiores sunt dolor nihil, optio in
      nam tenetur animi modi officia minus sint cum provident eos. Lorem ipsum
      dolor sit, amet consectetur adipisicing elit. Suscipit dolorum, cumque
      maiores perspiciatis minus ducimus distinctio repellat enim nisi odit
      debitis aut facilis possimus quam eius excepturi eum asperiores nam?
      Perspiciatis cum quaerat saepe sint, exercitationem quod est molestiae!
      Inventore nisi tempore, quasi minima accusantium modi voluptatibus nihil!
      Aliquam inventore quis voluptas obcaecati ducimus deserunt tempore
    </p>
  </div>
</div>
