import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface Offer {
  id: number;
  title: string;
  description: string;
  type: string;
  link: string;
}

@Injectable({
  providedIn: 'root',
})
export class OffersService {
  constructor(private http: HttpClient) {}

  async getOffers(): Promise<Observable<Offer[]>> {
    const headers: Record<string,string> = {
      'Authorization': sessionStorage.getItem('token')!
    }
    const path = environment.apiBasePath + 'offer/getOffers';
    return this.http.get<Offer[]>(path, {headers});
  }

  async setOffers(data: any): Promise<Observable<Offer>> {
    const headers: Record<string,string> = {
      'Authorization': sessionStorage.getItem('token')!
    }
    const body = data;
    const path = environment.apiBasePath + 'offer/addOffer';
    return this.http.post<Offer>(path, body, {headers});
  }

  async editOffer(data: any, id: number): Promise<Observable<Offer>> {
    const headers: Record<string,string> = {
      'Authorization': sessionStorage.getItem('token')!
    }
    const body = { ...data, id: id };
    const path = environment.apiBasePath + 'offer/editOffer';
    return this.http.put<Offer>(path, body, {headers});
  }

  async deleteOffer(id: number) {
    const headers: Record<string,string> = {
      'Authorization': sessionStorage.getItem('token')!
    }
    const path = `${environment.apiBasePath}offer/deleteOffer/${id}`;
    const result = this.http.delete<Offer>(path, {headers});
    result.subscribe((response) => {
      // console.log('response', response);
    });
  }
}
