import { Component, OnInit, ViewChild } from '@angular/core';
import { Faqs, GestionService } from 'src/app/_services/gestion.service';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.css'],
})
export class FaqsComponent implements OnInit {
  faqs: Faqs[] = [];
  isLoaded: boolean = false;
  constructor(private gestionService: GestionService) {}

  async ngOnInit(): Promise<void> {
    (await this.gestionService.getFaqs()).subscribe((data) => {
      this.faqs = data;
      this.isLoaded = true;
    });
  }
}
