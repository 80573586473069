import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface Comunicate {
  title: string;
  description: string;
  link: string;
  id: number;
  img: string;
}

@Injectable({
  providedIn: 'root',
})
export class ComunicateService {
  constructor(private http: HttpClient) {}

  async getComunicates(): Promise<Observable<Comunicate[]>> {
    const headers: Record<string,string> = {
      'Authorization': sessionStorage.getItem('token')!
    }
    const path = environment.apiBasePath + 'comunicate/getComunicates';
    return this.http.get<Comunicate[]>(path, {headers});
  }

  async getComunicatesImg(): Promise<Observable<Comunicate[]>> {
    const headers: Record<string,string> = {
      'Authorization': sessionStorage.getItem('token')!
    }
    const path = environment.apiBasePath + 'comunicate/getComunicateImg';
    return this.http.get<Comunicate[]>(path, {headers});
  }

  async setComunicate(data: any): Promise<Observable<Comunicate>> {
    const headers: Record<string,string> = {
      'Authorization': sessionStorage.getItem('token')!
    }
    const img = data.img;
    const body = {
      title: data.title,
      description: data.description,
      link: data.link,
      img,
    };
    const formData: FormData = new FormData();
    formData;
    formData.append('title', data.title);
    formData.append('description', data.description);
    formData.append('link', data.link);
    formData.append('img', img);

    const path = environment.apiBasePath + 'comunicate/uploadComunicate';
    return this.http.post<Comunicate>(path, formData, {headers});
  }

  async editComunicate(data: any, id: number): Promise<Observable<Comunicate>> {
    const headers: Record<string,string> = {
      'Authorization': sessionStorage.getItem('token')!
    }
    const body = { ...data, id: id };
    const path = environment.apiBasePath + 'comunicate/editComunicate';

    const formData: FormData = new FormData();
    formData.append('id', '' + id);
    formData.append('title', data.title);
    formData.append('description', data.description);
    formData.append('link', data.link);
    formData.append('img', data.img);

    return this.http.put<Comunicate>(path, formData, {headers});
  }

  async deleteComunicate(id: number) {
    const headers: Record<string,string> = {
      'Authorization': sessionStorage.getItem('token')!
    }
    const path = `${environment.apiBasePath}comunicate/deleteComunicate/${id}`;
    const result = this.http.delete<Comunicate>(path, {headers});
    result.subscribe((response) => {
      // console.log('response', response);
    });
  }
}
